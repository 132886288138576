import throwErrorBoundary from 'helpers/throwErrorBoundary'
import { useEffect } from 'react'

const ThrowErr = (): null => {
  useEffect(() => {
    throwErrorBoundary('mfe_error')
  }, [])
  return null
}

export default ThrowErr
