/**
 * Sets a custom attribute key and value in the new relic analytics plugin.
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setcustomattribute/
 * @param {string} key str - key index for attribute
 * @param {string} value str - the value for the attribute
 * Note: 255 character limit per attribute:
 * https://docs.newrelic.com/docs/data-apis/custom-data/custom-events/data-requirements-limits-custom-event-data/
 * @param {boolean} persist bool - whether new relic should persist this attribute across page loads until session ends
 * @return string of what was set or empty str if unable to set
 */
const setNewRelicAttribute = (
  key: string,
  value: string,
  persist = true
): string => {
  const { newrelic } = window
  if (!newrelic) {
    return ''
  }

  try {
    newrelic.setCustomAttribute(key, value, persist)
    return `${key}:${value}:${persist}`
  } catch (e) {
    console.warn(
      'New Relic setCustomAttribute function unavailable, update the New Relic script or ensure it has fully loaded before function is invoked.'
    )
    return ''
  }
}

export default setNewRelicAttribute
