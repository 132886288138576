import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'

export const MainWrapper = styled.main`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`

export const DashboardContainer = styled.div`
  width: 100%;

  @media ${query.greaterThanLarge} {
    max-width: 1114px;
  }
`
