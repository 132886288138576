import { LANGUAGE_KEY, SUPPORTED_LANGUAGES } from 'strings/lang/lang.constants'
import { type SupportedLanguage } from 'syf-js-utilities'
/**
 * Gets the value of the currently selected user language in local storage
 * at the language key, while ensuring the value is only a supported language.
 * @return {string} allowed language string value like 'en'
 */
const getLanguageInLocalStorage = (): SupportedLanguage => {
  const { EN: ENGLISH } = SUPPORTED_LANGUAGES
  const selectedLanguage = localStorage.getItem(LANGUAGE_KEY)?.toUpperCase()
  return SUPPORTED_LANGUAGES[selectedLanguage] ?? ENGLISH
}

export default getLanguageInLocalStorage
