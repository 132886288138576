import { DEFAULT_AUTH_FLOW } from 'const/constants'
import { authFlow } from 'const/session'
import type { AuthFlowType } from 'typings/Auth'
import getAppSessionItem from '../appSessionItem/getAppSessionItem'

/**
 * Gets authFlow value from session storage
 * @returns string, ex. 'paylater'
 */
const getSessionAuthFlow = (): AuthFlowType => {
  const sessionAuthFlow = getAppSessionItem(authFlow) as AuthFlowType
  return sessionAuthFlow || DEFAULT_AUTH_FLOW
}

export default getSessionAuthFlow
