/**
 * Formats the host name coming from window.location.hostname so
 * it excludes the 'www.' if it exists.
 * Example www.syf.com => syf.com
 * @param host the host name part of the url ie www.synchrony.com
 * @returns string
 */
const normalizeHost = (host: Location['hostname']): string => {
  const WWW = 'www.'
  const isHostPrefixed = host.startsWith(WWW)

  return isHostPrefixed ? host.replace(WWW, '') : host
}

export default normalizeHost
