import { signInErrorTypes } from 'const/signInErrors'
import {
  BUSINESS_VALIDATION_FAILURE,
  IDENTITY_BUSINESS_ERROR
} from 'const/businessErrors'
import { ErrorResponseData } from 'typings/ErrorResponseData'
import { ResponseWithLinks } from 'helpers/getLinkInResponse'

const accountUnsupportedError: ErrorResponseData = {
  code: IDENTITY_BUSINESS_ERROR,
  transient: false,
  businessErrors: [
    {
      code: 'idp.quick-authenticate.account.unsupported',
      message: 'universal-activation account unsupported'
    }
  ],
  message: BUSINESS_VALIDATION_FAILURE,
  trackingId: '81a2d5ce-4d24-429b-b542-b81d30c8c9e7'
}

const iovationError: ErrorResponseData = {
  code: IDENTITY_BUSINESS_ERROR,
  transient: false,
  businessErrors: [
    {
      code: 'idp.quick-authenticate.error.csr3',
      message: 'universal-activation csr3 error'
    }
  ],
  message: BUSINESS_VALIDATION_FAILURE,
  trackingId: '81a2d5ce-4d24-429b-b542-b81d30c8c9e8'
}

const maxAttemptsError: ErrorResponseData = {
  code: 'gateway.client.badRequest',
  transient: false,
  message: BUSINESS_VALIDATION_FAILURE,
  trackingId: '65c66562-c727-47e4-858b-56c4c03f312d'
}

const authenticateErrorUniversalActivationNotFound: ErrorResponseData = {
  code: IDENTITY_BUSINESS_ERROR,
  message: BUSINESS_VALIDATION_FAILURE,
  trackingId: '06b64a6e-1e93-4d11-a89b-8e587dcca3bc',
  transient: false,
  businessErrors: [
    {
      code: signInErrorTypes.UNIVERSAL_ACTIVATION,
      message: 'account not found'
    }
  ]
}

const authenticateSuccessResponse: ResponseWithLinks = {
  links: [
    {
      rel: 'GET',
      name: 'Authorize',
      href: 'https://api-qa.syf.com/v3/oauth2/authorize?id_token_hint=eyJlbmMiOiJBMjU2Q0JDLUhTNTEyIiwiYWxnIjoiUlNBLU9BRVAtMjU2In0.FNVbprIVx7nPpy4-wnFQYDEWik47pUlKX6X_H5PIKV4VYFa2fxgvDfqcK2vdLbxoeeM27iBUxtiq9QVF3LJ-gsYDE2iVWhtRlHxgByLUEEsfZHnu_Zpcbvd-NI94CkH3Z5cVehJfB1stVFiSNUBIGfT5_I7xZA_zFdrk-1DoTE5cQF-YXDeLUo6_lu82nHaZyUEk6sRs6ngdS04ulZqcZvRGjZ9hlozNnmq3NpqH4e6KBoRko0pl3I4NuLlKmaQ0l9vHT_Ms9Kdv_v9wNBJG59_ABhC3Mg13_oCZol0xRCN5gX1-JmArj71KrdaP0-pVUPb3gTvBhumh88qB5wF85w.P6HeQFEkvWBL3e1Uq6i3hQ.9ncewKHj4skoHl8k8IZJrmZSFRbiNCguUVXiGPq9evO6CucEx783RhxQ756evy14jRlDlLCJxvyBgHKboGL4dJpn-luh6Yp2DRmO2w2QeKqK4i0lCbpC8k40ZMuH3GQOor3JWFol9ShnLVTUiDqnSv9lcyEX5BPHuS8g1pqbDGHKWYQtLQc-cyy3tW-2GoH1-ddkCuYngjzImFmmdSJpV-qe6qIW7dQm5R0Cad7J8hYOEjt3ujtrMFwtYE6H6wLxe1VDocCzWX2Ob2LNdrzLCuREm-EhQJCVqyFeNDSxvsOxd0zZAuJZphtTrZK5T-5T7o1S5Mb6KMYk6UxjtAtTsNKT_sNZUY54QASr-qz2IjiJRiV8LbggA6ORTaB910XtgqC1WU3mxtvdTlA6dc53tJS1SHM5SrUWTFWy4VSP9mhgIPv1Vb9qSAowW6phD7H_Ja5ogdjczX8_ecyZtKkQqveleOKtKFhd7_YdjbnN-b07kLdAbHZIaYDKFHbCf8TxH46X7Zg54etpV8IHLcvmd6Udsp-e1roPb__RyjQpimb_gFQtP21Uzz9NYj7TrZub_1OW5FfiEJAJFwGXgxfbzQbKBzv1AouHq1n0AzTPxpdshTyZFiTIUod77VgdWOTkxKHkam9haPTTEW524butXMsqJ8HBR7fN47b0jzXrs05NqGd27VyAxYl3Jb3yuUjiK50eF2awezwroMWB7c0jTf-7dt3QhwWMwOkzc3DR33aW2s_oQtoP6cS1VM1qtFuFMCwYALk2atT2La3ps7utHW43KW-_C_9WPx-Hwa7t8jmPgJydSUSHwjwxjAIyxBJwhYOJZrrtPLLDJFEz0bM7ddb0kEfeoLSSfj9oLSrzC79D8b0y2l3VY3W3OweSxcZh59DCaKP7hvA38Ks2BQCwJwvjUIbXun8HTr8eXw7QFDeXXkPMz2s0Xe9PjHzzYJkLU-JA0tcsIbQ_0KhcBMjWL0mCwA05vLyRPKGfjX5Xg_Wdml1lYMdYyGzxMfnXXyPJBJelOUHCisuqU2d05IHbs73Jan0kdLsBaUMVpYVihxYZTivPgXnB3DiY-CB9KewXjbCE1ksmKBthHRx5I6gcdQ.SyRNK7nhhG7sN3HnNYjfcRTAaaY8PAtJ5RdcyCAU3jA'
    }
  ]
}

const mockUniversalActivationData = {
  accountUnsupportedError,
  authenticateErrorUniversalActivationNotFound,
  authenticateSuccessResponse,
  iovationError,
  maxAttemptsError
}

export default mockUniversalActivationData
