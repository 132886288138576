import {
  colors as CLColors,
  consumerTheme
} from 'syf-component-library/defaultTheme'

const { gradientStart, gradientEnd, valueTextColor } = consumerTheme.meter

export const colors = {
  ...CLColors,
  green: '#3e8529',
  grey: '#94969a'
}

const components = {
  button: {
    background: '#34657f',
    primary: {
      text: '#FFFFFF',
      hover: '#0072ad'
    },
    secondary: {
      text: '#34657f',
      hover: '#ebf0f2'
    }
  },
  footer: {
    background: '#FFFFFF',
    text: '#333333',
    link: '#35657f'
  },
  datepicker: {
    navIcon: '#515151',
    shadow: 'rgba(16,24,40,0.25)',
    disabled: '#E0E0E0',
    legend: {
      currentDate: '#34657f',
      selectedDate: {
        fill: '#34657f',
        border: '#34657f',
        text: '#ffffff'
      },
      dueDate: {
        fill: '#ffffff',
        border: '#34657f',
        text: '#34657f'
      },
      pastDueDate: {
        fill: '#ffffff',
        border: '#AA0F00',
        text: '#656565'
      },
      lateFeeDate: {
        fill: '#757575',
        border: '#757575',
        text: '#ffffff'
      }
    }
  }
}

const theme = {
  ...consumerTheme,
  meter: {
    valueTextColor,
    gradientStart,
    gradientEnd
  },
  ...components,
  ...colors
}

export type DefaultThemeType = typeof theme

export default theme
