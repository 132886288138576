// Number of cards to render in account dashboard
export const BATCH_SIZE = 12

// Used to differentiate the authflows for ssn+phone sign in and revolving account sign in
export const CARD_AUTH_FLOW = 'card'
export const PAYLATER_AUTH_FLOW = 'paylater'
export const UNIVERSAL_ACTIVATION_FLOW = 'activate'
export const SECURED_INSTALLMENTS_FLOW = 'securedinstallments'
export const DEFAULT_AUTH_FLOW = PAYLATER_AUTH_FLOW
// Activation Flow Steps
export const CARD_INPUT = 'CARD_INPUT'
export const MERCHANT_LOOKUP = 'MERCHANT_LOOKUP'
export const UNIVERSAL_ACTIVATION = 'UNIVERSAL_ACTIVATION'

// identifies peaks manually logged events for new relic
export const NEW_RELIC_MANUAL_EVENT = 'MANUAL_LOG_EVENT'

// Number of characters accepted on sign in forms
export const INPUT_LIMITS = {
  ACCOUNT_NUMBER: 16,
  CREDIT_CARD: 16,
  SECURITY_CODE: 3,
  PHONE_NUMBER: 10,
  SSN: 4,
  ZIP_CODE: 5
}

// Number of digits to group when masking a credit card
export const CREDIT_CARD_MASK_DIGIT_GROUP = 4

// REGEX to filter non digit characters from a string
export const FILTER_NON_DIGITS_REGEX = /[^0-9]/g

// REGEX to check if string is only non-digits
export const CHECK_NON_DIGITS_REGEX = /^[^\d]+$/

// class name to change placeholder color of sign in inputs on error
export const RED_PLACEHOLDER = 'red-placeholder'

// standardize synchrony homepage base url for any links redirected to synchrony pages
export const SYF_HOME_BASE_URL = 'https://www.synchrony.com/'

// find account url for universal activation flow
export const SYF_FIND_ACCOUNT_URL = `${SYF_HOME_BASE_URL}help/find-account`
