export const featureFlagsKey = 'PEAKSfeatureFlags'
export const featureFlagsFetchErrorKey = 'PEAKSfeatureFlagsError'
export const featureFlagsEventName = 'PEAKSflagsFetched'

// Feature flags
export const REVOLVING_CARD_ROUTE_FLAG = 'isRevolvingRouteEnabled'
export const SECURED_INSTALLMENTS_ROUTE_FLAG = 'isSecuredRouteEnabled'
export const UNIVERSAL_ACTIVATION_ROUTE_FLAG = 'isUniversalRouteEnabled'
export const PAY_LATER_ROUTE_FLAG = 'isPayLaterRouteEnabled'
export const QUALTRICS_FLAG = 'isQualtricsEnabled'
