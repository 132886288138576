import { formPost, getQueryParam } from 'syf-js-utilities'
import getLinkInResponse from 'helpers/getLinkInResponse'
import { setNewRelicPageAction } from 'helpers/newRelic'
import {
  AuthorizeMutateProps,
  AuthorizeMutationOption,
  AuthorizeUseMutationProps
} from './authorizeMutation.types'

export const authorizeURL = 'v3/oauth2/authorize'

export const getAuthorizeOptions = ({
  config,
  mutationOptions
}: AuthorizeUseMutationProps): AuthorizeMutationOption => {
  const { AUTH_DOMAIN } = config

  return {
    // 1 key since each login overwrites the previous fetch
    mutationKey: [authorizeURL],
    mutationFn: async ({
      completeResponse,
      language
    }: AuthorizeMutateProps) => {
      const ID_TOKEN_KEY = 'id_token_hint'
      const linkURL = getLinkInResponse(completeResponse)
      const queryParams = new URL(linkURL).search
      const idToken = getQueryParam(ID_TOKEN_KEY, queryParams)

      const formBody = {
        id_token_hint: idToken,
        lang: language
      }
      const fullAuthorizeUrl = new URL(authorizeURL, AUTH_DOMAIN).href
      // log authorize start with jwt token for better tracking/debugging auth flow
      setNewRelicPageAction(`Start ${authorizeURL}`, formBody)
      // simulate a form submit because fetch 302 redirect page in browser, /authorize 302 redirects on success
      return formPost(fullAuthorizeUrl, formBody)
    },
    ...mutationOptions
  }
}
