import {
  ENGLISH_LONG,
  ENGLISH_SHORT,
  SPANISH_LONG,
  SPANISH_SHORT
} from 'const/language'
import { SUPPORTED_LANGUAGES } from 'strings/lang/lang.constants'
import { SupportedLanguage } from 'syf-js-utilities'

const { EN } = SUPPORTED_LANGUAGES

/**
 * Handles what language string the Header component should display. When the Header is transparent and the screen is xs then the short version of the language should be displayed
 */
const getHeaderLanguageText = (
  language: SupportedLanguage,
  isLessThanMobile: boolean
): string => {
  if (isLessThanMobile) {
    return language === EN ? SPANISH_SHORT : ENGLISH_SHORT
  }

  return language === EN ? SPANISH_LONG : ENGLISH_LONG
}

export default getHeaderLanguageText
