import { Component, ErrorInfo, ReactNode, isValidElement } from 'react'
import ErrorComponent from 'ui/molecules/ErrorComponent'

interface ErrorBoundaryProps {
  children: ReactNode
  /** Custom component to show instead of default error component on error */
  customFallback?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
  /** contains the error information tha we might wanna check against */
  error: Error | null
}
/**
 * Wrapper component that catches any errors in the component tree. Instead of breaking the app
 * completely, it will render the fallback UI passed.
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: null
    }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error })
    console.error('Error boundary:', error, errorInfo)
  }

  render(): ReactNode {
    const { hasError, error } = this.state
    const { children, customFallback } = this.props

    if (hasError && error) {
      // so we don't mess up trying to render an unrenderable custom fallback
      const shouldShowCustomFallback = isValidElement(customFallback)
      return shouldShowCustomFallback ? (
        customFallback
      ) : (
        <ErrorComponent error={error} />
      )
    }

    return children
  }
}

export default ErrorBoundary
