import { useContext } from 'react'
import { SettingsContext, SettingsState } from 'context/settingsContext'

/**
 * Get the values in the Settings context provider:
 * forceApiError, setForceApiError, isMockDataEnabled, toggleMockData
 */
const useSettings = (): SettingsState => {
  const currentContext = useContext(SettingsContext)
  if (currentContext === undefined) {
    throw new Error(
      'SettingsContext used outside of its Provider, make sure you are using this in a child component of the Provider'
    )
  }
  return currentContext
}

export default useSettings
