import { QUALTRICS_EVENTS } from 'const/qualtrics'
import { setSFDDLprop } from './analytics/SFDDLHelpers'
import { PAGE_INFO_PROPS } from './analytics/analytics.types'

declare global {
  interface Window {
    QSI: {
      API: {
        load: () => Promise<void>
        run: () => void
        unload: () => void
      }
    }
  }
}

/**
 * @description Unloads and reloads the Qualtrics interceptors so that the conditions they use
 * can be re-validated in case some of them change.
 * @param {QUALTRICS_EVENTS} action Specifies what qualtrics event is being triggered in order to make the required changes for it to be correctly executed
 */
const reloadQualtrics = (action?: QUALTRICS_EVENTS): void => {
  if (!window.QSI?.API) {
    return
  }

  if (action === QUALTRICS_EVENTS.OPEN_MAP) {
    setSFDDLprop(PAGE_INFO_PROPS.PageFunction, 'payments')
  }

  window.QSI.API.unload()
  // Use setTimeout wrapper to move the QSI.API.load function call to the end of the execution callstack, in case the QSI.API.unload function call doesn't execute immediately.

  window.setTimeout(() => {
    window.QSI.API.load().then(window.QSI.API.run)
  })
}

export default reloadQualtrics
