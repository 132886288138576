import { sessionIdName } from 'const/session'
import getTransId from './getTransId'

/**
 * Gets the correlationId value from session storage. Fallback to a random
 * string if no correlationId available.
 * @returns str - the correlation id value, looks like "d9c4eb73-58e8-4b66-a8f9-0dbdc76e3586"
 */
const getCorrelationId = (): string => {
  let correlationId = sessionStorage.getItem(sessionIdName)
  if (!correlationId) {
    correlationId = getTransId()
    sessionStorage.setItem(sessionIdName, correlationId)
  }
  return correlationId
}

export default getCorrelationId
