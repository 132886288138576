import { ReactElement, useEffect, useState } from 'react'
import { i18n } from 'strings/i18n'
import { Inline, Stack } from 'syf-component-library/ui/spacing'
import getTimeOfDay from './getTimeOfDay'
import {
  GreetingContainer,
  GreetingContentContainer,
  LeftContentContainer,
  PersonalGreetingContainer,
  PersonalGreetingHeading
} from './PersonalGreeting.styles'

const MINUTE = 60000

const PersonalGreeting = (): ReactElement => {
  const [timeOfDay, setTimeOfDay] = useState(getTimeOfDay(new Date()))

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeOfDay(getTimeOfDay(new Date()))
    }, MINUTE)

    return () => {
      clearInterval(timer)
    }
  }, [])

  // Containers added to mimic behavior of header's logo so that both can be aligned
  return (
    <GreetingContainer>
      <GreetingContentContainer>
        <LeftContentContainer>
          <PersonalGreetingContainer>
            <Inline
              respondToBreakpoints={['huge']}
              hideOnBreakpointUp="large"
            />
            <div>
              <PersonalGreetingHeading weight="light">
                {i18n({
                  personalGreeting: timeOfDay
                })}
              </PersonalGreetingHeading>
              <Stack size="medium" hideOnBreakpointDown="medium" />
            </div>
            <Inline size="base" hideOnBreakpointUp="medium" />
          </PersonalGreetingContainer>
        </LeftContentContainer>
      </GreetingContentContainer>
    </GreetingContainer>
  )
}

export default PersonalGreeting
