import styled from 'styled-components'
import { spacing } from 'syf-component-library/defaultTheme'
import { H3 } from 'syf-component-library/ui/typography'

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const LoadingText = styled(H3)`
  color: white;
  max-width: 200px;
  text-align: center;
  padding-top: ${spacing.large}px;
`
