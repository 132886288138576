import { mfeErrorModalContainerId } from 'const/modalContainerIds'
import { ReactElement } from 'react'
import { i18n } from 'strings/i18n'
import Inset from 'syf-component-library/ui/spacing/Inset'
import Modal from 'ui/molecules/Modal'

interface MFEErrorModalProps {
  isMFEOpen: boolean
  handleCloseModal: () => void
}
const MFEErrorModal = ({
  isMFEOpen,
  handleCloseModal
}: MFEErrorModalProps): ReactElement => {
  return (
    <Modal
      isOpen={isMFEOpen}
      handleClose={() => handleCloseModal()}
      headerTitle={i18n({ mfeErrorModal: 'modalTitle' })}
      id="mfe-error-modal-modal-id"
      containerId={mfeErrorModalContainerId()}
    >
      <Inset all="medium">{i18n({ mfeErrorModal: 'content' })}</Inset>
    </Modal>
  )
}

export default MFEErrorModal
