import type { PropsWithChildren, ReactElement } from 'react'
import { createActorContext } from '@xstate/react'
import { accountCardMachine } from 'machines/accountCard'

export const AccountCardLoadingContext = createActorContext(
  accountCardMachine,
  {
    devTools: true
  }
)

const AccountCardMachine = ({ children }: PropsWithChildren): ReactElement => {
  return (
    <AccountCardLoadingContext.Provider>
      {children}
    </AccountCardLoadingContext.Provider>
  )
}

export default AccountCardMachine
