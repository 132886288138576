import { ReactElement, Suspense, lazy } from 'react'
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'
import { isMockData } from 'syf-js-utilities'
import routes from 'const/routes'
import config from 'const/config'
import { startAuthentication } from 'helpers/authUtils'
import RequiresAuth, {
  RequiresAuthProps
} from 'ui/molecules/RequiresAuth/RequiresAuth'
import AppUI from 'ui/molecules/AppUI'
import AbsoluteRedirect from 'ui/atoms/AbsoluteRedirect'
import LoadingView from 'ui/molecules/LoadingView/LoadingView'
import RoutesErrorElement from 'ui/molecules/RoutesErrorElement'
import LogoutCard from 'ui/pages/LogoutCard'
import AlertsAdapter from 'ui/adapters/AlertsAdapter'
import SignInRouter from 'ui/pages/SignInRouter/SignInRouter'
import prependSlash from 'helpers/prependSlash'
import {
  CARD_AUTH_FLOW,
  PAYLATER_AUTH_FLOW,
  SECURED_INSTALLMENTS_FLOW
} from 'const/constants'
import AuthorizedSignedInCheck from 'ui/molecules/AuthorizedSignedInCheck'
import RouteEnabledCheck from 'ui/molecules/RouteEnabledCheck'
import {
  PAY_LATER_ROUTE_FLAG,
  REVOLVING_CARD_ROUTE_FLAG,
  SECURED_INSTALLMENTS_ROUTE_FLAG
} from 'const/flags/globals'
import type { AuthFlowType } from 'typings/Auth'
import { PageNotFound } from 'ui/pages'
import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'

// pages lazy loaded since we don't need all of them right on page load
const AccountsDashboard = lazy(
  () => import('ui/pages/AccountsDashboard/AccountsDashboard')
)
const GetAccessToken = lazy(() => import('ui/pages/GetAccessToken'))
const PaylaterSignIn = lazy(() => import('ui/pages/PaylaterSignIn'))
const RevolvingCardSignIn = lazy(() => import('ui/pages/RevolvingSignIn'))
const SecuredInstallmentsSignIn = lazy(
  () => import('ui/pages/SecuredInstallmentsSignIn')
)
const UniversalActivation = lazy(() => import('ui/pages/UniversalActivation'))
const UniversalActivationCheck = lazy(
  () => import('ui/molecules/UniversalActivationCheck')
)
const ActivationHandlerAdapter = lazy(
  () => import('ui/adapters/ActivationHandlerAdapter')
)
const ActivationSuccessAdapter = lazy(
  () => import('ui/adapters/ActivationSuccessAdapter')
)
const ActivationErrorAdapter = lazy(
  () => import('ui/adapters/ActivationErrorAdapter')
)

const { BASENAME, APP_ENV, LOCAL_HOST } = config

const requiresAuthProps: Omit<RequiresAuthProps, 'children'> = {
  authorizeCallback: startAuthentication,
  isMockData: isMockData(APP_ENV)
}

const AppRoutes = (): ReactElement => {
  const isNotProd = APP_ENV !== 'production'
  const indexRouteAuthFlow = getSessionAuthFlow()

  /*
   * Redirects urls like qa.syncronycredit.com/quickaccess/{LOCAL ROUTE} to localhost version
   * because auth requires redirects to a syf hosted domain, but we want our
   * query params to get passed along to the localhost version when we are doing local dev.
   */
  const renderLocalRedirectRoutes = () => {
    // no need for this logic that makes development easier in prod
    return isNotProd ? (
      <>
        <Route
          path={`${routes.LOCAL_GET_TOKEN}`}
          element={
            <AbsoluteRedirect
              to={`${LOCAL_HOST}/${BASENAME}/${routes.GET_TOKEN}`}
            />
          }
        />
        <Route
          path={`${routes.LOCAL_LOGIN}`}
          element={
            <AbsoluteRedirect
              to={`${LOCAL_HOST}/${BASENAME}/${routes.LOGIN}`}
            />
          }
        />
      </>
    ) : null
  }

  const routerRoutes = createRoutesFromElements(
    <Route
      path={routes.HOME}
      element={<AppUI />}
      errorElement={<RoutesErrorElement />}
    >
      <Route
        path={routes.SIGNOUT}
        element={
          <Suspense fallback={<></>}>
            <LogoutCard />
          </Suspense>
        }
      />
      <Route path={routes.LOGIN} element={<SignInRouter />} />
      <Route
        path={routes.LOGIN_LOAN}
        element={
          <RouteEnabledCheck
            featureFlag={PAY_LATER_ROUTE_FLAG}
            authFlow={PAYLATER_AUTH_FLOW}
          >
            <PaylaterSignIn />
          </RouteEnabledCheck>
        }
      />
      <Route
        path={routes.LOGIN_REVOLVING}
        element={
          <RouteEnabledCheck
            featureFlag={REVOLVING_CARD_ROUTE_FLAG}
            authFlow={CARD_AUTH_FLOW}
          >
            <RevolvingCardSignIn />
          </RouteEnabledCheck>
        }
      />
      <Route
        path={routes.LOGIN_SECURED_INSTALLMENTS}
        element={
          <RouteEnabledCheck
            featureFlag={SECURED_INSTALLMENTS_ROUTE_FLAG}
            authFlow={SECURED_INSTALLMENTS_FLOW}
          >
            <SecuredInstallmentsSignIn />
          </RouteEnabledCheck>
        }
      />
      <Route
        path={routes.UNIVERSAL_ACTIVATION}
        element={<UniversalActivationCheck />}
      >
        <Route index element={<UniversalActivation />} />
        <Route
          path={routes.ACTIVATE_CARD}
          element={<ActivationHandlerAdapter />}
        />
        <Route
          path={routes.ACTIVATE_SUCCESS}
          element={<ActivationSuccessAdapter />}
        />
        <Route path={routes.ERROR} element={<ActivationErrorAdapter />} />
      </Route>
      <Route
        index
        element={
          <AuthorizedSignedInCheck
            authFlow={indexRouteAuthFlow as AuthFlowType}
            signedInRoute={routes.DASHBOARD}
          >
            <Navigate to={routes.LOGIN} />
          </AuthorizedSignedInCheck>
        }
      />
      <Route
        path={routes.DASHBOARD}
        element={
          <Suspense fallback={<></>}>
            <RequiresAuth {...requiresAuthProps}>
              <AccountsDashboard />
            </RequiresAuth>
          </Suspense>
        }
      />
      <Route
        path={routes.ALERTS}
        element={
          <RequiresAuth {...requiresAuthProps}>
            <AlertsAdapter />
          </RequiresAuth>
        }
      />
      <Route
        path={routes.GET_TOKEN}
        element={
          <Suspense fallback={<LoadingView />}>
            <GetAccessToken />
          </Suspense>
        }
      />

      {renderLocalRedirectRoutes()}
      <Route
        path="*"
        element={
          <Suspense fallback={<LoadingView showText={false} />}>
            <PageNotFound />
          </Suspense>
        }
      />
    </Route>
  )

  const router = createBrowserRouter(routerRoutes, {
    basename: prependSlash(BASENAME)
  })

  return <RouterProvider router={router} />
}

export default AppRoutes
