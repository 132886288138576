import config, { APPS_GPSHOPPER_DIRECTORY } from 'const/config'
import { WindowSize } from 'syf-component-library/ui/customHooks/useWindowWidth'
import getImageURL from './getImageURL'

const { ASSETS_HOST } = config
const { synchrony } = APPS_GPSHOPPER_DIRECTORY

/**
 * Handles the logo shown in the header.
 * - For transparent Header the text of the SYF logo is white.
 * - For non-transparent Header the text is black but should be hidden for xs screens
 */
const getHeaderLogo = (
  windowWidth: WindowSize,
  isTransparent: boolean
): string => {
  if (windowWidth.isLessThanTablet) {
    return `${ASSETS_HOST}${synchrony}syf_icon.svg`
  }

  return isTransparent
    ? getImageURL('syf-logo-white')
    : `${ASSETS_HOST}${synchrony}logo.svg`
}

export default getHeaderLogo
