import { ReactElement, Suspense, lazy, useEffect } from 'react'
import getSessionTokenAccounts from 'helpers/sessionTokenAccounts/getSessionTokenAccounts'
import { Navigate, useNavigate } from 'react-router'
import routes from 'const/routes'
import prependSlash from 'helpers/prependSlash'
import { useMFEConfigs } from 'hooks'
import { isFeatureAvailable } from 'helpers/authFlows/isFeatureAvailable'
import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'
import { AlertsWrapper } from './AlertsAdapter.styles'

const SyfAlerts = lazy(
  () =>
    import(
      /* webpackChunkName: "mfe-alerts" */
      /* webpackPrefetch: true */
      'alerts-mfe'
    )
)
const AlertsAdapter = (): ReactElement => {
  const navigate = useNavigate()
  const { getAlertsConfig } = useMFEConfigs()
  const accountIds = getSessionTokenAccounts()
  const sessionAuthFlow = getSessionAuthFlow()

  useEffect(() => {
    /**
     * (temp workaround) for now sending the user back to dashboard if there are no alerts
     * like when the page is reloaded and the accounts info is lost from the cache
     */
    if (accountIds.length === 0) {
      navigate(prependSlash(routes.DASHBOARD))
    }
  }, [accountIds])

  /**
   * The isFeatureAvailable check takes place here to ensure sessionAuthFlow variable has already been populated on the AppRoutes component
   */
  return isFeatureAvailable[sessionAuthFlow]?.alerts ? (
    <Suspense fallback={<></>}>
      {accountIds.length > 0 && (
        <AlertsWrapper>
          <SyfAlerts {...getAlertsConfig()} />
        </AlertsWrapper>
      )}
    </Suspense>
  ) : (
    <Navigate to={routes.HOME} />
  )
}

export default AlertsAdapter
