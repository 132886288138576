import type { ReactElement } from 'react'
import { createActorContext } from '@xstate/react'
import { orchestratorMachine } from 'machines/orchestrator'
import { STATES } from 'const/orchestratorMachine'
import AccountCardMachine from './AccountCardMachine'
import type { OrchestratorState } from './Orchestrator.types'

export const OrchestratorContext = createActorContext(orchestratorMachine, {
  devTools: true
})

export const useOrchestratorState = (): OrchestratorState => {
  const [state] = OrchestratorContext.useActor()

  return {
    accountId: state.context.accountId,
    isMAPOpen: state.matches(STATES.MAP_IS_OPEN),
    isDetailsOpen: state.matches(STATES.DETAILS_IS_OPEN),
    isActivityOpen: state.matches(STATES.ACTIVITY_IS_OPEN),
    isMockTriggersOpen: state.matches(STATES.MOCK_TRIGGERS_IS_OPEN)
  }
}

const OrchestratorProviders = ({
  children
}: React.PropsWithChildren): ReactElement => {
  return (
    <OrchestratorContext.Provider>
      <AccountCardMachine>{children}</AccountCardMachine>
    </OrchestratorContext.Provider>
  )
}

export default OrchestratorProviders
