import { ReactElement } from 'react'
import { useSettings } from 'hooks'
import { CheckBoxWrap } from 'ui/App/AppWrapper.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

const EnableMSWCheckbox = (): ReactElement => {
  const { isMockDataEnabled, toggleMockData } = useSettings()

  const handleClick = () => {
    toggleMockData()
  }

  return (
    <CheckBoxWrap
      isChecked={isMockDataEnabled}
      onChange={handleClick}
      icon={isMockDataEnabled && <FontAwesomeIcon icon={faCheck} />}
    >
      {`${isMockDataEnabled ? 'Turn Off' : 'Turn On'} Mock Data`}
    </CheckBoxWrap>
  )
}

export default EnableMSWCheckbox
