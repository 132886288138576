import { SupportedLanguage } from 'syf-js-utilities'
import getLanguageWithCountryCode from './getLanguageWithCountryCode'

export const langMetaElementQuery = '[http-equiv="content-language"]'
/**
 * Changes the language in \<html lang="..."> and \<meta http-equiv="content-language" content="...">
 *
 * These attributes are used by various programs, search engines included, to help figure out what language page is written in. This is helpful when trying to match the right content to the right user.
 * @see https://www.woorank.com/en/edu/seo-guides/best-practices-for-language-declaration
 * @param selectedLanguage
 */

const setLanguageInMetaTag = (selectedLanguage: SupportedLanguage) => {
  const langMetaElement = document.querySelector(langMetaElementQuery)
  const languageWithCountryCode = getLanguageWithCountryCode(selectedLanguage)
  if (langMetaElement) {
    langMetaElement.setAttribute('content', languageWithCountryCode)
  }
  const htmlMainElement = document.querySelector('html')
  if (htmlMainElement) {
    htmlMainElement.setAttribute('lang', languageWithCountryCode)
  }
}

export default setLanguageInMetaTag
