import { ReactElement, useState } from 'react'
import { useSettings } from 'hooks'
import throwErrorBoundary from 'helpers/throwErrorBoundary'
import { Body } from 'syf-component-library/ui/typography'
import { CheckBoxWrap } from 'ui/App/AppWrapper.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

/**
 * Component that throws an error on click of a button to test ErrorBoundary
 * or toggles an error to happen on API fetch
 */
const ThrowsErrorCheckbox = (): ReactElement => {
  const [isError, setIsError] = useState(false)

  const { forceApiError, setForceApiError } = useSettings()

  const apiErrorToggleStateLabel = forceApiError ? 'Disable' : 'Enable'

  /** Sets whether the testQuery should be forced to respond w/ error when fetched */
  const handleToggleAPIError = () => {
    setForceApiError(!forceApiError)
  }

  /** Throws a generic error */
  const ThrowError = () => {
    return throwErrorBoundary('ThrowsError component')
  }

  /** renders the buttons to trigger errors */
  const errorToggles = (
    <>
      <CheckBoxWrap isChecked={false} onChange={() => setIsError(true)}>
        <Body>Throw Error</Body>
      </CheckBoxWrap>
      <CheckBoxWrap
        isChecked={forceApiError}
        onChange={() => handleToggleAPIError()}
        icon={forceApiError && <FontAwesomeIcon icon={faCheck} />}
      >
        <Body>{`${apiErrorToggleStateLabel}`} Test API Error</Body>
      </CheckBoxWrap>
    </>
  )

  return isError ? <ThrowError /> : errorToggles
}

export default ThrowsErrorCheckbox
