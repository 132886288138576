/**
 * Redirect is a component similar to AbsoluteRedirect except that
 * it uses the Navigate component to redirect and it also takes
 * additional search params if any. This component also was created
 * to avoid any additional reloads or flashing screen for the user.
 */

import React from 'react'
import { Navigate, NavigateProps, useSearchParams } from 'react-router-dom'

const Redirect: React.FC<NavigateProps> = ({
  to,
  replace,
  state,
  relative
}) => {
  const [searchParams] = useSearchParams()
  const params = {}

  searchParams.forEach((value, key) => {
    params[key] = value
  })

  const strParams = new URLSearchParams(params).toString()
  const redirectWithParams = strParams ? `${to}?${strParams}` : to

  return (
    <Navigate
      to={redirectWithParams}
      replace={replace}
      state={state}
      relative={relative}
    />
  )
}

export default Redirect
