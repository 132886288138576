import { useQuery } from '@tanstack/react-query'
import getQueryParam from 'syf-js-utilities/formats/getQueryParam'
import defaultConfig from 'const/config'
import fetchBasic from 'services/helpers/fetchBasic'
import getApiHostforAuthService from 'helpers/getApiHostForAuthService'
import { startAuthentication } from 'helpers/authUtils'
import { setNewRelicPageAction } from 'helpers/newRelic'
import {
  IntrospectQueryData,
  IntrospectQueryProps,
  IntrospectQueryResult
} from './introspectQuery.types'

export const introspectURL = 'v3/oauth2/introspect'

const defaultOptions = { config: defaultConfig }
/**
 * Calls the introspect endpoint which grabs a session id for the user. Used for auth and other calls.
 * https://developer.syf.com/api-reference/oauth2-api-v3-v3#operation/Introspect
 * @param props @see {@link IntrospectQueryProps} - options for the introspect query
 * @returns the same as useQuery returns, an obj with .data and other query stuff
 */
export const useIntrospectQuery = ({
  config = defaultConfig,
  queryOptions = {}
}: IntrospectQueryProps = defaultOptions): IntrospectQueryResult => {
  const { APIGEE_CLIENT_ID } = config
  // override to use an actual auth server on local instead of sandbox for realistic dev
  const apiHostToUse = getApiHostforAuthService(config)

  const useQueryOptions = {
    // 1 id key since the session id is unique and requested once
    queryKey: [introspectURL],
    queryFn: async () => {
      const headers: HeadersInit = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }

      // urlSearchParams sends x-www-form-urlencoded form data content type when POSTed
      const JWT_TOKEN_KEY = 'token'
      const body: BodyInit = new URLSearchParams({
        token: getQueryParam(JWT_TOKEN_KEY),
        'X-SYF-Client-Id': APIGEE_CLIENT_ID,
        token_type_hint: 'jwt'
      })

      const requestConfig: RequestInit = {
        method: 'POST',
        headers,
        body
      }

      const fetchOptions = {
        baseURL: apiHostToUse,
        init: requestConfig
      }
      // log introspect start for tracking user flow/to get debug info from the id token
      setNewRelicPageAction(`Start ${requestConfig.method} ${introspectURL}`, {
        token: `${body.get(JWT_TOKEN_KEY)}`
      })
      // fetchBasic because this is an auth call so no auth headers available yet
      const response = await fetchBasic<IntrospectQueryData>(
        introspectURL,
        fetchOptions
      )

      const isTokenExpired = response.active === false && response.returnTo
      const isTokenInvalid = response.active === false && !response.returnTo

      // if the idToken is malformed or nonexistent in the system
      if (isTokenInvalid) {
        throw new Error('token.invalid')
      }
      if (isTokenExpired) {
        // need new id token from /authorize auth server for valid authentication
        startAuthentication()
      }
      return response
    },
    // so errors in the response trigger the nearest react error boundary
    useErrorBoundary: true,
    ...queryOptions
  }

  return useQuery(useQueryOptions)
}

export default useIntrospectQuery
