import { getAppSessionItem } from 'helpers/appSessionItem'
import { tokenAccountsName } from 'const/session'

/**
 * Gets the account id array for the user from /token stored in session storage.
 * @returns arr - the account id array from /token or [] if it doesnt exist
 */
const getSessionTokenAccounts = (): string[] => {
  const accountIdsString = getAppSessionItem(tokenAccountsName)
  return accountIdsString ? JSON.parse(accountIdsString) : []
}

export default getSessionTokenAccounts
