const throwErrorBoundary = (message: string): never => {
  const isLocal = window.location.host.includes('localhost')
  if (isLocal) {
    /*
     * On local, react error overlay still shows when the error boundary gets triggered
     * which covers up the screen and makes it harder to check fallback so hide it
     */
    const reactErrorOverlayStyleOverride = document.createElement('style')
    reactErrorOverlayStyleOverride.textContent = `iframe { display: none; }`
    document.head.append(reactErrorOverlayStyleOverride)
  }

  throw new Error(message)
}

export default throwErrorBoundary
