import styled from 'styled-components'

export const RouteWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
`

export const ErrorButtonWrap = styled.aside`
  margin: auto;
  width: 200px;
`
