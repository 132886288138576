import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import PageContainer from 'ui/pages/Pages.subcomponents'
import { spacing } from 'syf-component-library/defaultTheme'

export const ErrorContainer = styled(PageContainer)`
  justify-content: flex-start;
  flex-grow: 1;

  margin: ${spacing.xlarge}px;

  * {
    box-sizing: border-box;
  }
`

export const InnerContainer = styled.div`
  text-align: center;
  width: 768px;
  position: relative;

  @media ${query.lessThanLarge} {
    width: 343px;
  }
`
