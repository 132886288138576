import { QueryClient } from '@tanstack/react-query'
import { QueryClient as QueryClientLegacy } from 'react-query'

/*
 * staleTime determines for how long a certain response will still be considered fresh (or not stale),
 * dismissing the need for a new request.
 */

const config = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 3 * 60 * 1000 // the result is considered stale after 3 minutes
    }
  }
}

const queryClient = new QueryClient(config)

/** Required by make-a-payment as it is using the older version of react-query */
export const queryClientLegacy = new QueryClientLegacy(config)

export default queryClient
