import accountSummaryV2QueryMock from 'syf-api/accountSummary/accountSummaryV2QueryMock'
import type { AccountSummaryGetResponse } from 'syf-typings'

const installmentMockData = {
  principalAmount: '500.00',
  monthlyAmountDue: '23.08',
  totalAmountPaid: '415.68',
  endDate: '2025-03-20',
  interestRate: '9.99',
  loanTerm: '24',
  startDate: '2022-03-31',
  purchaseAmount: '500.00',
  payoffValidThrough: '2022-06-02',
  termRemaining: 22,
  principalRemaining: '89.42'
}
const accountSummarySecuredInstallmentsMock = (): AccountSummaryGetResponse => {
  const data = accountSummaryV2QueryMock()
  const { product } = data
  return {
    ...data,
    product: {
      ...product,
      type: 'INSTALLMENT_LOAN_SECURED',
      lineOfBusiness: 'SECURED_INSTALLMENT',
      displayName: 'Product Type Secured Installment'
    },
    installment: installmentMockData,
    conditions: ['OPEN']
  }
}

export default accountSummarySecuredInstallmentsMock
