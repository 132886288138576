import { setAppSessionItem } from 'helpers/appSessionItem'
import { tokenAccountsName } from 'const/session'

/**
 * Converts the account id array from the /token api to string and sets into session storage.
 * Account id array should look like ['7196eead-65d0-4ab6-ad8b-2aa42c0b8f18']
 * @param accounts arr - an array of account id strings
 * @return na
 */
export const setSessionTokenAccounts = (accounts: string[] = []): void => {
  const accountsString = JSON.stringify(accounts)
  return setAppSessionItem(tokenAccountsName, accountsString)
}

export default setSessionTokenAccounts
