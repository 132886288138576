import {
  ReactElement,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { startMSW, stopMSW } from 'mocks/mswBrowser'
import {
  getLocalMockDataEnabled,
  setLocalMockDataEnabled
} from 'helpers/localMockDataEnabled'
import config from 'const/config'

interface SettingsProviderProps {
  children: ReactNode | ReactNode[]
}

export interface SettingsState {
  /** If testQuery API should be forced to fail */
  forceApiError: boolean
  /** Set true/false for forceApiError in React and localStorage state */
  setForceApiError: (newState: this['forceApiError']) => void
  /** Whether mocking data handlers should be enabled */
  isMockDataEnabled: boolean
  /** Starts/stops msw data handlers and sets isMockDataEnabled state in the context */
  toggleMockData: () => void
}

export const SettingsContext = createContext<SettingsState | undefined>(
  undefined
)

/**
 * Settings are app level options that affect functioning of the application.
 * Wraps passed children components in the Settings context so they can access
 * the values set in the provider or update those state values.
 * @param props {children: jsx elements to wrap the provider around}
 * @returns context provider wrapped components
 */
const SettingsProvider = ({
  children
}: SettingsProviderProps): ReactElement => {
  // detect if the env is prod or uat for starting or not the mock service worker
  const { APP_ENV } = config
  const isProdOrUAT =
    APP_ENV === 'production' ||
    window.location.hostname === 'uat.synchronycredit.com'
  // if we have it already, use this as the init value to persist setting across reloads
  const isApiErrorToggledAlready =
    localStorage.getItem('toggleApiError') === 'true'
  const [forceApiError, setForceApiErrorState] = useState(
    isApiErrorToggledAlready
  )

  // we autotrigger mocking in local
  const isMockDataEnabledAlready = getLocalMockDataEnabled()
  const [isMockDataEnabled, setIsMockDataEnabledState] = useState(
    isMockDataEnabledAlready
  )

  // custom state setter for forceApiError to set the state in localStorage and here
  const setForceApiError = (newState: boolean) => {
    // also set in local storage for persistence across page reloads
    localStorage.setItem('toggleApiError', JSON.stringify(newState))

    setForceApiErrorState(newState)
  }

  const toggleMockData = () => {
    isMockDataEnabled ? stopMSW() : startMSW()
    // persist setting in localstorage for ease of mocking
    setLocalMockDataEnabled(!isMockDataEnabled)
    setIsMockDataEnabledState(!isMockDataEnabled)
  }

  useEffect(() => {
    // if data mocking disabled, we can't force the api error to occur so reset
    if (!isMockDataEnabled) {
      setForceApiError(false)
    } else if (!isProdOrUAT) {
      startMSW()
    }
  }, [isMockDataEnabled])

  // values of settings and possible setters for those settings
  const settingsState: SettingsState = {
    forceApiError,
    setForceApiError,
    isMockDataEnabled,
    toggleMockData
  }

  const memoizedValues = useMemo(
    () => settingsState,
    [forceApiError, isMockDataEnabled]
  )

  return (
    <SettingsContext.Provider value={memoizedValues}>
      {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider
