import config, { APPS_GPSHOPPER_DIRECTORY } from 'const/config'

/**
 * Returns the URL of an image asset based on the given name and directory path.
 * @param {string} name - The name of the image asset.
 * @param {string} directoryPath - The path of the directory containing the image assets, default is 'peaks'
 * @returns {string} The URL of the image asset.
 */
const getImageURL = (name: string): string => {
  const { ASSETS_HOST } = config
  const { peaks } = APPS_GPSHOPPER_DIRECTORY
  switch (name) {
    case 'cat':
      return `${ASSETS_HOST}${peaks}cat.svg`
    case 'sleepy-cat':
      return `${ASSETS_HOST}${peaks}sleepy-cat.svg`
    case 'looking':
      return `${ASSETS_HOST}${peaks}desktop_page_not_found.svg`
    case 'blocked':
      return `${ASSETS_HOST}${peaks}blocked.svg`
    case 'logout':
      return `${ASSETS_HOST}${peaks}logout.svg`
    case 'syf-logo-white':
      return `${ASSETS_HOST}${peaks}syf-logo-white.svg`
    default:
      return ''
  }
}

export default getImageURL
