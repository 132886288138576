import { ReactElement } from 'react'
import { i18n } from 'strings/i18n'
import { Inset, Stack } from 'syf-component-library/ui/spacing'
import config, { APPS_GPSHOPPER_DIRECTORY } from 'const/config'
import Card from 'syf-component-library/ui/atoms/Card/Card'
import { H4, H5 } from 'syf-component-library/ui/typography'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { useUser } from 'hooks'
import {
  CardImage,
  Container,
  InnerContainer,
  PageNotFoundHeader
} from './PageNotFound.styles'

const PageNotFound = (): ReactElement => {
  // force footer to rerender on language change, that's stored in the user context
  useUser()
  const windowWidth = useWindowSize()
  const { ASSETS_HOST } = config
  const { peaks } = APPS_GPSHOPPER_DIRECTORY

  const imageName = windowWidth.isLessThanTablet
    ? 'mobile_page_not_found.svg'
    : 'desktop_page_not_found.svg'
  const pageNotFoundImage = `${ASSETS_HOST}${peaks}${imageName}`

  return (
    <Container>
      <InnerContainer>
        <Stack size="large" respondToBreakpoints={['small']} />
        <Card>
          <PageNotFoundHeader>
            <CardImage
              src={pageNotFoundImage}
              alt={i18n({ errors: 'pageNotFoundImgAlt' })}
            />
          </PageNotFoundHeader>
          <Inset all="xlarge">
            <H4 weight="bold">{i18n({ errors: 'sorryPage' })}</H4>
            <Stack size="medium" hideOnBreakpointDown="small" />
            <Inset top="large" hideOnBreakpointUp="medium" />
            <H5>{i18n({ errors: 'pageNotFound' })}</H5>
            <Stack size="large" />
          </Inset>
        </Card>
      </InnerContainer>
    </Container>
  )
}

export default PageNotFound
