import { Fragment, ReactElement } from 'react'
import Body from 'syf-component-library/ui/typography/Body'
import SystemNavigation from 'syf-component-library/ui/patterns/SystemNavigation'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import { i18n } from 'strings/i18n'
import handleLogout from 'helpers/handleLogout'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { analyticsEvents } from 'helpers/analytics/events'
import routes from 'const/routes'
import useHeaderNavigation from 'hooks/useHeaderNavigation'
import { useUser } from 'hooks'
import { ICONS } from 'const/icons'
import config from 'const/config'
import getHeaderLogo from 'helpers/getHeaderLogo'
import { isFeatureAvailable } from 'helpers/authFlows/isFeatureAvailable'
import { getAppSessionItem } from 'helpers/appSessionItem'
import { authFlow } from 'const/session'
import { SUPPORTED_LANGUAGES } from 'strings/lang/lang.constants'
import { ENGLISH_LONG, SPANISH_LONG } from 'const/language'
import { UNIVERSAL_ACTIVATION_FLOW } from 'const/constants'
import type { HeaderProps } from './Header.types'
import TransparentHeader from '../TransparentHeader'
import { DropdownRow, IconWrapper, NavigationContainer } from './Header.styles'

const { EN } = SUPPORTED_LANGUAGES

const Header = ({ isTransparent = false }: HeaderProps): ReactElement => {
  const sessionAuthFlow = getAppSessionItem(authFlow)
  const isUniversalActivationFlow =
    sessionAuthFlow === UNIVERSAL_ACTIVATION_FLOW
  const windowWidth = useWindowSize()
  const { currentLanguage, givenName, handleLanguageChange, isLoggedIn } =
    useUser()
  const { goToAlerts, goToDashboard } = useHeaderNavigation()

  const isAlertsPage =
    window.location.pathname === `/${config.BASENAME}/${routes.ALERTS}`

  const handleLogoutClick = async () => {
    triggerAnalyticsEvent(analyticsEvents.logoutClick())
    await handleLogout()
  }

  const dropDownList = [
    <DropdownRow
      onClick={handleLogoutClick}
      buttonType="text"
      key="sign-out"
      data-type="customer menu"
      data-reason="sign out"
      data-object="button"
      data-analytics="clicktrackingevent"
      disabled={!isLoggedIn}
    >
      <IconWrapper>{ICONS.SIGNOUT}</IconWrapper>
      <Body>{i18n({ buttons: 'signOut' })}</Body>
    </DropdownRow>
  ]
  const backOptions =
    isLoggedIn && isAlertsPage
      ? {
          backButtonLabel: i18n({ buttons: 'back' }),
          backButtonIcon: ICONS.ANGLELEFT,
          handleBackButtonClick: goToDashboard
        }
      : {}

  const alertsMenuIconProps = isFeatureAvailable[sessionAuthFlow]?.alerts
    ? {
        alertsIcon: ICONS.ALERTS,
        handleAlertsClick: goToAlerts
      }
    : {}

  // different options/appearances for the top right user menu based on login state
  const navUserMenuPropsIfLoggedIn = isLoggedIn
    ? {
        dropDownList,
        profileName: givenName,
        dropDownIcon: ICONS.ANGLEDOWN,
        ...alertsMenuIconProps,
        ...backOptions
      }
    : {
        dropDownList: [<Fragment key="nav-dropdown-empty" />]
      }

  return isTransparent || isUniversalActivationFlow ? (
    <TransparentHeader
      language={currentLanguage}
      languageIcon={ICONS.GLOBE}
      handleLanguageClick={handleLanguageChange}
    />
  ) : (
    <NavigationContainer className="header">
      <SystemNavigation
        logo={getHeaderLogo(windowWidth, isTransparent)}
        altText={i18n({ header: 'synchronyLogoImgAlt' })}
        profileIcon={ICONS.USER}
        language={currentLanguage === EN ? SPANISH_LONG : ENGLISH_LONG}
        languageIcon={ICONS.GLOBE}
        handleLanguageClick={handleLanguageChange}
        closeIcon={ICONS.TIMES}
        {...navUserMenuPropsIfLoggedIn}
      />
    </NavigationContainer>
  )
}

export default Header
