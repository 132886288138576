const ROUTES = {
  HOME: '/',
  LOGIN_LOAN: 'signin/paylater',
  LOGIN_REVOLVING: 'signin/card',
  LOGIN_SECURED_INSTALLMENTS: 'signin/securedinstallments',
  LOGIN: 'signin',
  DASHBOARD: 'dashboard',
  ALERTS: 'alerts',
  LOCAL_LOGIN: 'local-signin',
  LOCAL_ACTIVATE: 'local-activate',
  GET_TOKEN: 'get-token',
  LOCAL_GET_TOKEN: 'local-get-token',
  SIGNOUT: 'signout',
  ERROR: 'error',
  UNIVERSAL_ACTIVATION: 'activate',
  ACTIVATE_CARD: 'card',
  ACTIVATE_SUCCESS: 'success'
}

export const AUTH_ROUTES = [
  `/${ROUTES.LOGIN}`,
  `/${ROUTES.LOCAL_LOGIN}`,
  `/${ROUTES.LOGIN_LOAN}`,
  `/${ROUTES.LOGIN_REVOLVING}`,
  `/${ROUTES.LOGIN_SECURED_INSTALLMENTS}`,
  `/${ROUTES.UNIVERSAL_ACTIVATION}`,
  `/${ROUTES.SIGNOUT}/`
]

export const ROUTES_WITHOUT_GREETING = AUTH_ROUTES.concat([
  `/${ROUTES.ALERTS}`,
  `/${ROUTES.UNIVERSAL_ACTIVATION}/${ROUTES.ACTIVATE_CARD}`,
  `/${ROUTES.UNIVERSAL_ACTIVATION}/${ROUTES.ACTIVATE_SUCCESS}`,
  `/${ROUTES.UNIVERSAL_ACTIVATION}/${ROUTES.ERROR}`
])

export default ROUTES
