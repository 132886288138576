import { getAppSessionItem } from 'helpers/appSessionItem'
import { MOCK_CARDS_LIMIT } from 'mocks/ui/const/mockInputs'
import resizeArray from 'helpers/resizeArray'
import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'
import { SECURED_INSTALLMENTS_FLOW } from 'const/constants'

/**
 * Gets an array of mock account ids. Number returned is based on the session storage value
 * or the default of 3.
 * @returns accountIds array {string}
 */
const getMockAccountIds = (): string[] => {
  const mockedAccountIds =
    getSessionAuthFlow() !== SECURED_INSTALLMENTS_FLOW
      ? [
          'b5d38f4b-0a07-404d-a7c9-1851b58c7a39',
          'i5k38m4g-0h0g-404g-h7jg-1851i58j7h3g',
          '2ec7be46-00f2-4367-9ef8-acb42e3a7ca3'
        ]
      : ['b5d38f4b-0a07-404d-a7c9-1851b58c7a39']
  const mockTotalAccounts = mockedAccountIds.length
  /** We get from session storage to define how many accounts should we show */
  const mockAccountsParam = getAppSessionItem(MOCK_CARDS_LIMIT)

  // If the param is not passed, we show all of them
  const mockAccountsNumber =
    mockAccountsParam === '' ? mockTotalAccounts : +mockAccountsParam
  // we also show all the cards if param is not numeric
  const mockedAmountOfCards = Number.isNaN(mockAccountsNumber)
    ? mockTotalAccounts
    : mockAccountsNumber

  return resizeArray(mockedAccountIds, mockedAmountOfCards)
}

export default getMockAccountIds
