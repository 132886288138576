import { Fragment, ReactElement } from 'react'
import useWindowSize from 'syf-component-library/ui/customHooks/useWindowWidth'
import SystemNavigation from 'syf-component-library/ui/patterns/SystemNavigation/SystemNavigation'
import { Body } from 'syf-component-library/ui/typography'
import { i18n } from 'strings/i18n'
import { SUPPORTED_LANGUAGES } from 'strings/lang/lang.constants'
import config, { APPS_GPSHOPPER_DIRECTORY } from 'const/config'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { analyticsEvents } from 'helpers/analytics/events'
import handleLogout from 'helpers/handleLogout'

import { useUser } from 'hooks'
import { ICONS } from 'const/icons'
import { ENGLISH_LONG, SPANISH_LONG } from 'const/language'
import {
  DropdownRow,
  IconWrapper,
  NavigationContainer
} from '../Header/Header.styles'

const ErrorHeader = (): ReactElement => {
  const windowWidth = useWindowSize()
  const { currentLanguage, handleLanguageChange, isLoggedIn } = useUser()

  const { ASSETS_HOST } = config
  const { synchrony } = APPS_GPSHOPPER_DIRECTORY
  const { EN: ENGLISH } = SUPPORTED_LANGUAGES

  const handleLogoutClick = async () => {
    triggerAnalyticsEvent(analyticsEvents.logoutClick())
    await handleLogout()
  }

  const dropDownList = [
    <DropdownRow
      onClick={handleLogoutClick}
      buttonType="text"
      key="sign-out"
      data-type="customer menu"
      data-reason="sign out"
      data-object="button"
      data-analytics="clicktrackingevent"
      disabled={!isLoggedIn}
    >
      <IconWrapper>{ICONS.SIGNOUT}</IconWrapper>
      <Body>{i18n({ buttons: 'signOut' })}</Body>
    </DropdownRow>
  ]
  const navUserMenuPropsIfLoggedIn = isLoggedIn
    ? {
        dropDownList,
        profileName: 'Logged In User',
        dropDownIcon: ICONS.ANGLEDOWN
      }
    : {
        dropDownList: [<Fragment key="nav-dropdown-empty" />]
      }

  return (
    <NavigationContainer>
      <SystemNavigation
        logo={
          windowWidth.isLessThanTablet
            ? `${ASSETS_HOST}${synchrony}syf_icon.svg`
            : `${ASSETS_HOST}${synchrony}logo.svg`
        }
        altText={i18n({ header: 'synchronyLogoImgAlt' })}
        profileIcon={ICONS.USER}
        language={currentLanguage === ENGLISH ? SPANISH_LONG : ENGLISH_LONG}
        languageIcon={ICONS.GLOBE}
        handleLanguageClick={handleLanguageChange}
        closeIcon={ICONS.TIMES}
        {...navUserMenuPropsIfLoggedIn}
      />
    </NavigationContainer>
  )
}
export default ErrorHeader
