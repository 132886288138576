import { AccountSummaryGetResponse } from 'syf-typings'

interface AccountWithDetails extends AccountSummaryGetResponse {
  accountId: string
}

export const CacheQueriesType = {
  SUMMARY: 'summary'
} as const

export type CacheQueriesType =
  (typeof CacheQueriesType)[keyof typeof CacheQueriesType]

export type CacheQueriesDataType = {
  accountId: string
}
/** To be extended when we add more queries to use */
export type ReturnGetQueryDataFromCache = AccountWithDetails
