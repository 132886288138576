/**
 * Sets an item in session storage with our app's namespace prefix.
 * @param key the name of the item in storage to store the value under
 * @param value the value to store in sessionStorage
 * @param prefix? prefix to add to key for this app for namespacing our app's session storage, default is set in const/session
 */
const setAppSessionItem = (key: string, value: string, prefix = ''): void =>
  sessionStorage.setItem(`${prefix}${key}`, value)

export default setAppSessionItem
