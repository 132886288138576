import tokenName, {
  authDomain,
  currentSelectedAccountName,
  platformInformation,
  tokenAccountsName,
  tokenExpirationTime,
  tokenRequestTimestamp,
  tokenVerifier
} from 'const/session'

const clearSessionStorage = (): void => {
  sessionStorage.removeItem(tokenName)
  sessionStorage.removeItem(tokenAccountsName)
  sessionStorage.removeItem(authDomain)
  sessionStorage.removeItem(tokenExpirationTime)
  sessionStorage.removeItem(tokenRequestTimestamp)
  sessionStorage.removeItem('givenName')
  sessionStorage.removeItem('correlationId')
  sessionStorage.removeItem('psccRedirectUrl')
  sessionStorage.removeItem('invalid_token')
  sessionStorage.removeItem('expired_token')
  sessionStorage.removeItem(currentSelectedAccountName)
  sessionStorage.removeItem(tokenVerifier)
  sessionStorage.removeItem(platformInformation)
}

export default clearSessionStorage
