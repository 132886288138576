import {
  CARD_AUTH_FLOW,
  PAYLATER_AUTH_FLOW,
  SECURED_INSTALLMENTS_FLOW
} from 'const/constants'

/**
 * Returns which features should be available based on the authFlow value.
 * @returns boolean - which features is available or not
 */
export const isFeatureAvailable = {
  [PAYLATER_AUTH_FLOW]: {
    alerts: true
  },
  [CARD_AUTH_FLOW]: {
    alerts: true
  },
  [SECURED_INSTALLMENTS_FLOW]: {
    alerts: false
  }
}
