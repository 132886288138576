import HTTPError from 'syf-js-utilities/typings/HTTPError'

/**
 * Error object returned when fetch encounters a response other than `ok` from a request.
 * Includes the formatted body data. Based on the HTTPError class.
 * @param response (obj) - the Response obj from an api call
 * @param data (any, prob string though) - the formatted body data from the response
 * @returns obj - a Error subclass object with more properties on it
 */
class HTTPDataError<TData = unknown> extends HTTPError {
  /** The formatted data in the response body stream */
  readonly data?: TData

  /** Error name, must redefine to make this writable */
  readonly name: string

  constructor(response: Response, data: TData) {
    super(response)
    /*
     * We need to manually set the prototype because of TS bug.
     * refer to https://stackoverflow.com/questions/68899615/how-to-expect-a-custom-error-to-be-thrown-with-jest
     */
    Object.setPrototypeOf(this, HTTPDataError.prototype)
    this.name = 'HTTPDataError'
    this.data = data
  }
}

export default HTTPDataError
