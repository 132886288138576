import { ConfigType, ENVS, QA_API_HOST } from 'const/config'

/**
 * Gets the API host domain value from the app's config constants ie
 * https://api-qa.syf.com. Used to override the default mock api host
 * with the actual api server domain for testing real auth in local development environments.
 * @returns str - the host domain url
 */
const getApiHostforAuthService = (config: ConfigType): URL['origin'] => {
  const { API_HOST, APP_ENV } = config
  const isLocal = APP_ENV === ENVS.local
  return isLocal ? QA_API_HOST : API_HOST
}

export default getApiHostforAuthService
