import { analyticsConfig } from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import { SFDDLPageInfoProps } from './analytics.types'

/**
 * setSFDDLprop helps to assign a specific value to a determined property of the _SFDDL object
 * @param sfddlProperty
 * @param value
 */
export const setSFDDLprop = (sfddlProperty: string, value: string): void => {
  if (SFDDLPageInfoProps.includes(sfddlProperty)) {
    window._SFDDL.pageInfo[sfddlProperty] = value
  }
}

/**
 * getSFFDLProp helps to retrieve the value of a determined property of the _SFDDL object
 * @param sfddlProperty
 */
export const getSFFDLProp = (sfddlProperty: string): string => {
  return window._SFDDL?.pageInfo[sfddlProperty]
}

/**
 * setAnalyticsConfigDefaultProp helps to assign a specific value to a determined property of the analyticsConfig object
 * @param analyticsProperty
 * @param value
 */
export const setAnalyticsConfigDefaultProp = (
  analyticsProperty: string,
  value: string
): void => {
  if (SFDDLPageInfoProps.includes(analyticsProperty)) {
    analyticsConfig.defaults[analyticsProperty] = value
  }
}
