import styled from 'styled-components'
import H1 from 'syf-component-library/ui/typography/H1'
import query from 'syf-component-library/const/mediaQueries'
import { fontSizes, spacing } from 'syf-component-library/defaultTheme'

export const PersonalGreetingContainer = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.white};
`

export const PersonalGreetingHeading = styled(H1)`
  font-size: ${fontSizes.large}px;
  @media ${query.lessThanSmall} {
    text-align: center;
  }
`
export const GreetingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  @media ${query.lessThanMedium} {
    display: inline-block;
  }
`

export const GreetingContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  padding: 0 ${spacing.base}px;
  box-sizing: border-box;
  width: 100%;

  @media ${query.lessThanMedium} {
    display: flex;
  }
`
export const LeftContentContainer = styled.div`
  display: flex;
  align-items: center;
`
