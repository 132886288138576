import config, { HOME_PAGE } from 'const/config'
import isWhiteListHost from 'syf-js-utilities/helpers/isWhitelistHost'

/**
 * Takes in a url string, ensures it's a properly formatted URL,
 * and validates the host is on the whitelist, otherwise just go to the
 * home page for Peaks. Passes through query params.
 * @param url - string - the url to make safe ie https://www.synchrony.com
 * @returns string - safe, escaped url
 */
const makeSafeURL = (url: Location['href']): string => {
  const { BASENAME, LOCAL_HOST } = config
  // using the constructor to allow variables in the regex
  const localhostRegex = new RegExp(`${LOCAL_HOST}/${BASENAME}`)

  try {
    // encodes/escapes unsafe url characters
    const newURL = new URL(url)
    const { href } = newURL

    // need to allow our local dev server and syf urls to work
    const isLocalApp = localhostRegex.test(href)
    const isWhiteListed = isWhiteListHost(href)

    // if invalid we just go back home for safety
    return isLocalApp || isWhiteListed ? href : HOME_PAGE
  } catch (e) {
    return HOME_PAGE
  }
}

export default makeSafeURL
