import type { Hosts, MFEConfig } from 'syf-js-utilities/typings/mfeConfig'
import type { SupportedLanguage } from 'syf-js-utilities/typings/formats'
import type HTTPError from 'syf-js-utilities/typings/HTTPError'
import { SUPPORTED_LANGUAGES } from 'strings/lang/lang.constants'
import config, { sandboxApi } from './config'

interface PeaksMFEConfig extends MFEConfig {
  hosts: PeaksHostType
}

interface PeaksHostType extends Hosts {
  serviceFiles: URL
}

interface MFEConfigGeneratorOptions {
  // the host domain for api endpoints ie https://api-qa.syf.com
  apiHostURL?: URL['host']
  // the host domain for static assets like pics and text files
  assetsHostURL?: URL['host']
  // the current language ie en, es
  lang?: SupportedLanguage
  // an object that will be spread over everything to override any part of the mfe config
  overrides?: Partial<PeaksMFEConfig>
}

/**
 * Generates an MFE config object using the options provided or with defaults
 * based on the current config object if no options set.
 * @param options @see MFEConfigGeneratorOptions obj - options object to set various fields in the mfe config
 * @returns @see MFEConfig obj - an MFEConfig object
 */
export function generateMFEConfig(
  options: MFEConfigGeneratorOptions = {}
): PeaksMFEConfig {
  const { API_HOST, ASSETS_HOST } = config
  const {
    apiHostURL = API_HOST,
    assetsHostURL = ASSETS_HOST,
    lang = SUPPORTED_LANGUAGES.EN,
    overrides = {}
  }: MFEConfigGeneratorOptions = options

  return {
    /** Callback for all status codes not matching 2xx */
    apiErrorCallback: (err: HTTPError) => {
      console.error(err)
    },
    hosts: {
      apigee: new URL(apiHostURL),
      assets: new URL(new URL(assetsHostURL).origin),
      serviceFiles: new URL('https://images.synchronycredit.com/')
    },
    lang,
    ...overrides
  }
}

export const mfeConfig: PeaksMFEConfig = generateMFEConfig()

export const mockMfeConfig: PeaksMFEConfig = generateMFEConfig({
  apiHostURL: sandboxApi
})

/**
 * Returns either the mock data mfe config or the regular mfe config
 * depending on if mock data is enabled or not
 * @param isMockData bool - whether mock data is enabled or not
 * @returns obj - an mfe config object
 */
export const getMFEConfigForMockState = (isMockData = false): PeaksMFEConfig =>
  isMockData ? mockMfeConfig : mfeConfig
