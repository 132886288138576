import { getSessionID } from 'helpers/authUtils'

/**
 * Sets the session id in the new relic browser script to associate all
 * new relic events to a user during the current session to the /introspect generated session id
 * https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/setuserid/
 * @returns void
 */
const setNewRelicSessionUserId = (): void => {
  const sessionID = getSessionID()
  if (sessionID) {
    // since newrelic is loaded in the head as a render blocking script, this *should* exist, but just in case we can try/catch
    try {
      window.newrelic.setUserId(sessionID)
    } catch (e) {
      console.warn(
        'New Relic setUserId function unavailable, update the New Relic script or ensure it has fully loaded before setUserId function is invoked.'
      )
    }
  }
}

export default setNewRelicSessionUserId
