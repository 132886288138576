import { QUALTRICS_FLAG } from 'const/flags/globals'
import { useFeature } from 'flagged'
import reloadQualtrics from 'helpers/reloadQualtrics'

type QualtricsFunction = {
  reloadQualtrics: typeof reloadQualtrics
}

const useQualtrics = (): QualtricsFunction => {
  const isQualtricsEnabled = useFeature(QUALTRICS_FLAG)
  return {
    reloadQualtrics: isQualtricsEnabled ? reloadQualtrics : () => {}
  }
}

export default useQualtrics
