import { ReactElement, useEffect } from 'react'
import useMockToggles from 'mocks/hooks/useMockToggles/useMockToggles'
import { useSettings } from 'hooks'
import { CheckBoxWrap } from 'ui/App/AppWrapper.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from 'syf-component-library/ui/atoms/Tooltip'
import { faCheck, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'
import { TOGGLE_STRINGS } from './ToggleMockCheckbox.constants'
import type { ToggleMockButtonProps } from './ToggleMockCheckbox.types'

/**
 * Toggles on or off mocking for a specific feature or service, triggering an associated
 * msw override handler to be used if it is passed. Can also require mock data enabled for it
 * to be enabled.
 * @param ToggleMockButtonProps {object} name, mswOverrideHandlers?, requireMockDataEnabled?
 * @returns ReactElement {object}
 */
const ToggleMockCheckbox = ({
  name,
  mswOverrideHandlers,
  toolTip
}: ToggleMockButtonProps): ReactElement => {
  const { mockTogglesEnabled, switchMockToggles, updateMSWHandlers } =
    useMockToggles()
  const { isMockDataEnabled } = useSettings()

  const { ON, ENABLE, DISABLE } = TOGGLE_STRINGS

  const handleClick = () => {
    switchMockToggles(name, mswOverrideHandlers)
  }

  useEffect(() => {
    // keep msw handlers and toggle state in sync after page reloads
    const isMockSettingOn = sessionStorage.getItem(name) === ON
    if (isMockDataEnabled && isMockSettingOn && mswOverrideHandlers) {
      // only 1 toggle should be enabled so this should only override 1 mock handler
      updateMSWHandlers(mswOverrideHandlers)
    }
  }, [])

  const isMockSettingOn = mockTogglesEnabled.includes(name)
  // if it's on, then clicking would disable it and vice versa
  const buttonDisplayText = `${
    isMockSettingOn ? DISABLE : ENABLE
  } ${name.replaceAll('_', ' ')}`

  return (
    <CheckBoxWrap
      isChecked={isMockSettingOn}
      onChange={handleClick}
      icon={isMockSettingOn && <FontAwesomeIcon icon={faCheck} />}
    >
      {buttonDisplayText}
      {toolTip && (
        <Tooltip content={toolTip} placement="top-start" isInteractive>
          <span
            style={{
              fontSize: 12,
              marginLeft: 4
            }}
          >
            <FontAwesomeIcon icon={faCircleQuestion} size="lg" />
          </span>
        </Tooltip>
      )}
    </CheckBoxWrap>
  )
}

export default ToggleMockCheckbox
