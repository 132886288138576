import {
  CARD_AUTH_FLOW,
  PAYLATER_AUTH_FLOW,
  SECURED_INSTALLMENTS_FLOW,
  UNIVERSAL_ACTIVATION_FLOW
} from 'const/constants'
import type { AuthFlowType } from 'typings/Auth'
import getAppSessionItem from 'helpers/appSessionItem/getAppSessionItem'
import { authFlow as sessionAuthFlow } from 'const/session'
import getCountryLanguage from 'helpers/getCountryLanguage'
import { DefaultAnalyticsObject, PAGE_INFO_PROPS } from './analytics.types'
import { setAnalyticsConfigDefaultProp, setSFDDLprop } from './SFDDLHelpers'

declare global {
  interface Window {
    _SFDDL?: {
      pageInfo: Record<string, string>
    }
  }
}

/**
 * This function delete certain attributes that get set in the SFDDL object from other analytics events
 * Since these attributes are not part of the default SFDDL once they are set up they remain until manually deleted
 */

export const cleanDashboardAnalytics = (): void => {
  delete window._SFDDL.pageInfo.numberofaccounts
}

/** string used to identify events for secured installments auth flow */

export const securedInstallmentsText = 'secured-installments'

/**
 *
 * @param authFlow current authentication flow the user is in
 * @param totalAccounts number of accounts the user has
 * @returns the pageKind to be used for the analytics event
 */

export const getPageKindForDashboard = (
  authFlow: AuthFlowType,
  totalAccounts?: number
) => {
  switch (authFlow) {
    case SECURED_INSTALLMENTS_FLOW:
      return securedInstallmentsText
    case PAYLATER_AUTH_FLOW:
    case CARD_AUTH_FLOW:
    case UNIVERSAL_ACTIVATION_FLOW:
    default:
      return totalAccounts > 1 ? 'multi-loan-account' : 'single-loan-account'
  }
}

/**
 * Whenever an MFE is opened we need to set the default values for ClientName and Product
 * so that subsequent analytics event inside the MFE can have these values.
 */

export const setOpenMFEAnalyticsDefaults = (
  ClientName: string,
  Product: string,
  totalAccounts: number
): void => {
  const pageKind = getPageKindForDashboard(
    getAppSessionItem(sessionAuthFlow) as AuthFlowType,
    totalAccounts
  )
  setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.ClientName, ClientName)
  setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.Product, Product)
  setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.PageKind, pageKind)
  if (window._SFDDL?.pageInfo) {
    setSFDDLprop(PAGE_INFO_PROPS.ClientName, ClientName)
    setSFDDLprop(PAGE_INFO_PROPS.Product, Product)
    setSFDDLprop(PAGE_INFO_PROPS.PageKind, pageKind)
  }
}

/**
 *
 * @param errorMsg message the user is seeing on the screen
 * @param authFlow current authentication flow the user is in
 * @returns the object to be used for the analytics event
 */

export const getAnalyticsObjectForError = (
  errorMsg: string,
  authFlow: AuthFlowType
) => {
  const analyticsObject: DefaultAnalyticsObject = {
    ClientName: 'generic',
    Country_Language: getCountryLanguage(),
    PageKind: 'login',
    pageName: 'error',
    ErrorMessage: errorMsg
  }
  // to change for a switch if more cases are required later
  if (authFlow === SECURED_INSTALLMENTS_FLOW) {
    analyticsObject.PageSubFunction = securedInstallmentsText
    analyticsObject.MessageType = `${securedInstallmentsText} error`
  }
  return analyticsObject
}

/**
 *
 * @param authFlow current authentication flow the user is in
 * @returns the sub function to be used in the analytics event
 */

export const getPageSubfunction = (authFlow: AuthFlowType) => {
  if (authFlow === SECURED_INSTALLMENTS_FLOW) {
    return securedInstallmentsText
  }
  return ''
}
