import { useMFEConfigs } from 'hooks'
import { ReactElement, Suspense, lazy } from 'react'
import { SlideInModalLoader } from 'syf-component-library/ui/atoms'
import { i18n } from 'strings/i18n'
import Modal from 'ui/molecules/Modal'
import MFEErrorBoundary from 'ui/molecules/MFEErrorBoundary/MFEErrorBoundary'
import { MOCK_THROW_MFE } from 'mocks/ui/const/toggles'
import ThrowErr from 'ui/ThrowErr/ThrowErr'
import useResetMFEErrorKey from 'hooks/useResetMFEErrorKey'
import { useOrchestratorState } from 'orchestrator/Orchestrator'
import { refreshChunk } from 'helpers/utils/utils'
import { activityModalContainerId } from 'const/modalContainerIds'
import ActivityAccountHeader from './ActivityAccountHeader'

// defer load & code split this to a separate chunk for initial load perf
const ActivityTable = lazy(() =>
  refreshChunk(
    () =>
      import(
        /* webpackChunkName: "mfe-manage-my-acct" */
        /* webpackPrefetch: true */
        'mma-mfe'
      ),
    'activity-mfe'
  )
)

const ActivityAdapter = (): ReactElement => {
  const { accountId: selectedAccountId, isActivityOpen } =
    useOrchestratorState()
  const { getActivityConfig } = useMFEConfigs()
  const { isModalOpen, handleClose, ...activityMFEProps } = getActivityConfig()
  const { resetKey, handleReset } = useResetMFEErrorKey()
  const isAdapterOpen = isActivityOpen && selectedAccountId
  const tempFlagToThrow = sessionStorage.getItem(MOCK_THROW_MFE) === 'true'
  const containerId = activityModalContainerId(selectedAccountId)

  return (
    <MFEErrorBoundary key={resetKey} handleReset={handleReset}>
      <Modal
        isOpen={isModalOpen}
        handleClose={handleClose}
        headerTitle={i18n({ labels: 'activity' })}
        id="activity-modal-id"
        containerId={containerId}
        hideCloseIcon={false}
      >
        <Suspense fallback={<SlideInModalLoader />}>
          <ActivityAccountHeader accountId={selectedAccountId} />
          <ActivityTable {...activityMFEProps} />
          {isAdapterOpen && tempFlagToThrow && <ThrowErr />}
        </Suspense>
      </Modal>
    </MFEErrorBoundary>
  )
}

export default ActivityAdapter
