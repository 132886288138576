/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-var-requires */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { createRoot } from 'react-dom/client'
import App from 'ui/App/App'
import { DEV } from 'const/config'

if (DEV) {
  // force use of js module, mjs module causing compile errors, might get fixed in a future axe version
  import('@axe-core/react').then(({ default: axe }) => {
    axe(React, ReactDOM, 1000)
  })
}

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
