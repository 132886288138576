import type { ReactElement } from 'react'
import { ButtonProps } from 'syf-component-library/ui/atoms/Button/typings'
import { FullWidthButton as StyledFullWidthButton } from './FullWidthButton.styles'

const FullWidthButton: React.FC<ButtonProps> = (props): ReactElement => {
  const { children } = props
  return <StyledFullWidthButton {...props}>{children}</StyledFullWidthButton>
}

export default FullWidthButton
