import { useContext } from 'react'
import { MockTogglesContext } from './MockTogglesContext'
import type { MockTogglesState } from './MockTogglesContext.types'

/**
 * Get the values in the MockToggles context provider:
 * mockTogglesEnabled, switchMockToggles, setMockTogglesEnabled, updateMSWHandlers
 */
const useMockToggles = (): MockTogglesState => {
  const currentContext = useContext(MockTogglesContext)
  if (currentContext === undefined) {
    throw new Error(
      'MockTogglesContext used outside of its Provider, make sure you are using this in a child component of the Provider'
    )
  }
  return currentContext
}

export default useMockToggles
