import styled from 'styled-components'
import { spacing } from 'syf-component-library/defaultTheme'
import { Checkbox } from 'syf-component-library/ui/atoms'

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const CheckBoxWrap = styled(Checkbox)`
  padding: ${spacing.small}px;
`
