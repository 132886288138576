import { featureFlagsKey } from 'const/flags/globals'
import { FlagsQueryData } from 'services/flagsQuery/flagsQuery.types'

/**
 * Sets the passed feature flags data object into localStorage.
 * @param flagsData {object} - a feature flags json obj with name, version, and flags obj
 */
const setFeatureFlagsInStore = (flagsData: FlagsQueryData): void => {
  localStorage.setItem(featureFlagsKey, JSON.stringify(flagsData))
}

export default setFeatureFlagsInStore
