import { ReactElement, useState } from 'react'
import Collapse from 'syf-component-library/ui/atoms/Collapse'
import {
  MOCK_ACCOUNT_NOT_FOUND,
  MOCK_ACTIVATION_ACCOUNT_UNSUPPORTED,
  MOCK_ACTIVATION_ALREADY_ACTIVATED,
  MOCK_ACTIVATION_AUTH_SHELL,
  MOCK_ACTIVATION_EXTERNAL_STATUS,
  MOCK_ACTIVATION_INVALID_EXTERNAL_CODE,
  MOCK_ACTIVATION_IOVATION,
  MOCK_ACTIVATION_MAX_ATTEMPTS,
  MOCK_AUTH,
  MOCK_AUTHENTICATE_IOVATION,
  MOCK_AUTH_POST_ERROR,
  MOCK_AUTOPAY_ENROLLED,
  MOCK_BLOCKED_ACCOUNT,
  MOCK_CLIENT_ERROR,
  MOCK_EXPIRED_TOKEN,
  MOCK_INVALID_TOKEN,
  MOCK_NO_ACTIVE_ACCOUNTS,
  MOCK_PROXY_QUICK_AUTHENTICATE,
  MOCK_SESSION_INVALID,
  MOCK_SESSION_NOT_FOUND,
  MOCK_THROW_MFE,
  MOCK_TOKEN_SERVICE_ERROR
} from 'mocks/ui/const/toggles'
import { CARDS_LIMIT_MSG } from 'mocks/ui/const/mockValidationMsg'
import { MOCK_CARDS_LIMIT } from 'mocks/ui/const/mockInputs'
import ThrowsErrorCheckbox from 'ui/atoms/ThrowsError/ThrowsErrorCheckbox'
import EnableMSWCheckbox from 'mocks/ui/ToggleMSW'
import ToggleMockCheckbox from 'mocks/ui/molecules/ToggleMockCheckbox'
import MockInput from 'mocks/ui/molecules/MockInput'
import {
  authenticateActivationApiDesignProxyPostHandler,
  authenticateErrorBlockedAccountPostHandler,
  authenticateErrorIovationPostHandler,
  authenticateErrorSessionInvalidPostHandler,
  authenticateErrorSessionNotFoundPostHandler,
  autopayStatusEnrolledGetHandler,
  cardActivationAlreadyActivatedErrorHandler,
  cardActivationAuthShellErrorHandler,
  cardActivationExternalStatusErrorHandler,
  cardActivationInvalidExternalStatusErrorHandler,
  tokenServicePostErrorHandler,
  universalActivationErrorAccountUnsupportedPostHandler,
  universalActivationErrorIovationPostHandler,
  universalActivationErrorMaxAttemptsPostHandler
} from 'mocks/handlers'
import MockTogglesProvider from 'mocks/hooks/useMockToggles/MockTogglesContext'
import { MOCK_TRIGGER_TOOL_TIPS } from 'mocks/ui/const/mockTriggerToolTips'
import { cardsLimitOnChange } from 'mocks/ui/molecules/MockInput/MockInputUtils'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Body } from 'syf-component-library/ui/typography'
import {
  CollapseContainer,
  CollapseHeader,
  CollapseWrapper
} from './MockToggles.styles'
import type { Sections } from './MockToggles.types'
import {
  AUTHENTICATION_ID,
  CARDS_LIMIT_DEFAULT_VALUE,
  CUSTOM_MOCKS_ID,
  ERRORS_ID,
  PAYMENTS_ID,
  UNIVERSAL_ACTIVATION_ID
} from './MockToggles.constants'

const {
  INVALID_SESSION,
  SESSION_NOT_FOUND,
  INVALID_TOKEN,
  EXPIRE_TOKEN,
  CLIENT_ERROR,
  AUTH_POST_ERROR,
  AUTOPAY_ENROLLED,
  ACC_NOT_FOUND,
  NO_ACTIVE_ACCOUNTS,
  CARDS_LIMIT,
  SECURED_ACC_BLOCKED,
  PROXY_QUICK_AUTHENTICATE,
  ACTIVATION_ACCOUNT_UNSUPPORTED,
  ACTIVATION_IOVATION,
  ACTIVATION_MAX_ATTEMPTS,
  ACTIVATION_ALREADY_ACTIVATED,
  ACTIVATION_AUTH_SHELL,
  ACTIVATION_EXTERNAL_STATUS,
  ACTIVATION_INVALID_EXTERNAL_CODE
} = MOCK_TRIGGER_TOOL_TIPS

/**
 * MockToggles is composed of all the mocking toggle button components to trigger custom mock handlers
 * @param props
 * @returns Mocking toggle buttons
 */
const MockToggles = (): ReactElement => {
  const [sectionOpened, setSectionOpened] = useState<Sections>(null)

  const expandedIcon = faChevronUp
  const collapsedIcon = faChevronDown

  const toggleSection = (sectionToBeToggled: Sections): void => {
    const section =
      sectionToBeToggled === sectionOpened ? null : sectionToBeToggled
    setSectionOpened(section)
  }

  const isSectionOpen = (section: Sections): boolean => {
    return section === sectionOpened
  }

  return (
    <MockTogglesProvider>
      <EnableMSWCheckbox />

      {/* Authentication related mock triggers */}
      <CollapseWrapper>
        <CollapseHeader
          buttonType="secondary"
          onClick={() => toggleSection('authentication')}
          aria-expanded={isSectionOpen('authentication')}
          aria-controls={AUTHENTICATION_ID}
        >
          Authentication
          <FontAwesomeIcon
            icon={
              isSectionOpen('authentication') ? expandedIcon : collapsedIcon
            }
            size="sm"
          />
        </CollapseHeader>
        <Collapse
          expand={isSectionOpen('authentication')}
          id={AUTHENTICATION_ID}
          duration={0}
        >
          <CollapseContainer>
            <ToggleMockCheckbox
              name={MOCK_SESSION_INVALID}
              mswOverrideHandlers={authenticateErrorSessionInvalidPostHandler}
              toolTip={INVALID_SESSION}
            />
            <ToggleMockCheckbox
              name={MOCK_SESSION_NOT_FOUND}
              mswOverrideHandlers={authenticateErrorSessionNotFoundPostHandler}
              toolTip={SESSION_NOT_FOUND}
            />
            <ToggleMockCheckbox
              name={MOCK_INVALID_TOKEN}
              toolTip={INVALID_TOKEN}
            />
            <ToggleMockCheckbox
              name={MOCK_EXPIRED_TOKEN}
              toolTip={EXPIRE_TOKEN}
            />
            <ToggleMockCheckbox
              name={MOCK_CLIENT_ERROR}
              toolTip={CLIENT_ERROR}
            />
            <ToggleMockCheckbox name={MOCK_AUTH} />
            <ToggleMockCheckbox
              name={MOCK_AUTH_POST_ERROR}
              toolTip={AUTH_POST_ERROR}
            />
            <ToggleMockCheckbox
              name={MOCK_ACCOUNT_NOT_FOUND}
              toolTip={ACC_NOT_FOUND}
            />
            <ToggleMockCheckbox
              name={MOCK_NO_ACTIVE_ACCOUNTS}
              toolTip={NO_ACTIVE_ACCOUNTS}
            />
            <ToggleMockCheckbox
              name={MOCK_BLOCKED_ACCOUNT}
              mswOverrideHandlers={authenticateErrorBlockedAccountPostHandler}
              toolTip={SECURED_ACC_BLOCKED}
            />
          </CollapseContainer>
        </Collapse>
      </CollapseWrapper>

      {/* Other errors to be triggered */}
      <CollapseWrapper>
        <CollapseHeader
          buttonType="secondary"
          onClick={() => toggleSection('errors')}
          aria-expanded={isSectionOpen('errors')}
          aria-controls={ERRORS_ID}
        >
          Errors
          <FontAwesomeIcon
            icon={isSectionOpen('errors') ? expandedIcon : collapsedIcon}
            size="sm"
          />
        </CollapseHeader>

        <Collapse expand={isSectionOpen('errors')} id={ERRORS_ID} duration={0}>
          <CollapseContainer>
            <ThrowsErrorCheckbox />
            <ToggleMockCheckbox
              name={MOCK_AUTHENTICATE_IOVATION}
              mswOverrideHandlers={authenticateErrorIovationPostHandler}
            />
            <ToggleMockCheckbox
              name={MOCK_TOKEN_SERVICE_ERROR}
              mswOverrideHandlers={tokenServicePostErrorHandler}
            />
            <ToggleMockCheckbox name={MOCK_THROW_MFE} />
          </CollapseContainer>
        </Collapse>
      </CollapseWrapper>
      {/** MAP related mocks */}
      <CollapseWrapper>
        <CollapseHeader
          buttonType="secondary"
          onClick={() => toggleSection('payments')}
          aria-expanded={isSectionOpen('payments')}
        >
          Payments
          <FontAwesomeIcon
            icon={isSectionOpen('payments') ? expandedIcon : collapsedIcon}
            size="sm"
          />
        </CollapseHeader>
        <Collapse
          id={PAYMENTS_ID}
          expand={isSectionOpen('payments')}
          duration={0}
        >
          <CollapseContainer>
            <ToggleMockCheckbox
              name={MOCK_AUTOPAY_ENROLLED}
              toolTip={AUTOPAY_ENROLLED}
              mswOverrideHandlers={autopayStatusEnrolledGetHandler}
            />
          </CollapseContainer>
        </Collapse>
      </CollapseWrapper>

      {/* Universal Activation related errors */}
      <CollapseWrapper>
        <CollapseHeader
          buttonType="secondary"
          onClick={() => toggleSection('universalActivation')}
          aria-expanded={isSectionOpen('universalActivation')}
          aria-controls={UNIVERSAL_ACTIVATION_ID}
        >
          Universal Activation
          <FontAwesomeIcon
            icon={
              isSectionOpen('universalActivation')
                ? expandedIcon
                : collapsedIcon
            }
            size="sm"
          />
        </CollapseHeader>
        <Collapse
          expand={isSectionOpen('universalActivation')}
          id={UNIVERSAL_ACTIVATION_ID}
          duration={0}
        >
          <CollapseContainer>
            <Body weight="bold">Auth</Body>
            <ToggleMockCheckbox
              name={MOCK_ACTIVATION_ACCOUNT_UNSUPPORTED}
              mswOverrideHandlers={
                universalActivationErrorAccountUnsupportedPostHandler
              }
              toolTip={ACTIVATION_ACCOUNT_UNSUPPORTED}
            />
            <ToggleMockCheckbox
              name={MOCK_ACTIVATION_IOVATION}
              mswOverrideHandlers={universalActivationErrorIovationPostHandler}
              toolTip={ACTIVATION_IOVATION}
            />
            <ToggleMockCheckbox
              name={MOCK_ACTIVATION_MAX_ATTEMPTS}
              mswOverrideHandlers={
                universalActivationErrorMaxAttemptsPostHandler
              }
              toolTip={ACTIVATION_MAX_ATTEMPTS}
            />
            <ToggleMockCheckbox
              name={MOCK_PROXY_QUICK_AUTHENTICATE}
              mswOverrideHandlers={
                authenticateActivationApiDesignProxyPostHandler
              }
              toolTip={PROXY_QUICK_AUTHENTICATE}
            />
            {/* card activation */}
            <Body weight="bold">Card activation errors</Body>
            <ToggleMockCheckbox
              name={MOCK_ACTIVATION_EXTERNAL_STATUS}
              mswOverrideHandlers={cardActivationExternalStatusErrorHandler}
              toolTip={ACTIVATION_EXTERNAL_STATUS}
            />
            <ToggleMockCheckbox
              name={MOCK_ACTIVATION_ALREADY_ACTIVATED}
              mswOverrideHandlers={cardActivationAlreadyActivatedErrorHandler}
              toolTip={ACTIVATION_ALREADY_ACTIVATED}
            />
            <ToggleMockCheckbox
              name={MOCK_ACTIVATION_AUTH_SHELL}
              mswOverrideHandlers={cardActivationAuthShellErrorHandler}
              toolTip={ACTIVATION_AUTH_SHELL}
            />
            <ToggleMockCheckbox
              name={MOCK_ACTIVATION_INVALID_EXTERNAL_CODE}
              mswOverrideHandlers={
                cardActivationInvalidExternalStatusErrorHandler
              }
              toolTip={ACTIVATION_INVALID_EXTERNAL_CODE}
            />
          </CollapseContainer>
        </Collapse>
      </CollapseWrapper>
      {/** Custom internal mock data */}
      <CollapseWrapper>
        <CollapseHeader
          buttonType="secondary"
          onClick={() => toggleSection('customMocks')}
          aria-expanded={isSectionOpen('customMocks')}
          aria-controls={CUSTOM_MOCKS_ID}
        >
          Customize mock data
          <FontAwesomeIcon
            icon={isSectionOpen('customMocks') ? expandedIcon : collapsedIcon}
            size="sm"
          />
        </CollapseHeader>
        <Collapse
          id={CUSTOM_MOCKS_ID}
          expand={isSectionOpen('customMocks')}
          duration={0}
        >
          <CollapseContainer>
            <MockInput
              name={MOCK_CARDS_LIMIT}
              toolTip={CARDS_LIMIT}
              validationMsg={CARDS_LIMIT_MSG}
              defaultValue={CARDS_LIMIT_DEFAULT_VALUE}
              onChange={cardsLimitOnChange}
            />
          </CollapseContainer>
        </Collapse>
      </CollapseWrapper>
    </MockTogglesProvider>
  )
}

export default MockToggles
