import styled from 'styled-components'

/**
 *  CSS Ref: https://css-tricks.com/inclusively-hidden/
 */

export const NonVisibleText = styled.span`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
