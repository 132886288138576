import { getSessionID } from 'helpers/authUtils'
import getCorrelationId from 'helpers/getCorrelationId'
import getApiHostforAuthService from 'helpers/getApiHostForAuthService'
import fetchBasic from 'services/helpers/fetchBasic'
import getTransId from 'helpers/getTransId'
import { setNewRelicPageAction } from 'helpers/newRelic'
import {
  CompleteMutationData,
  CompleteMutationOption,
  CompleteUseMutationProps
} from './completeMutation.types'

export const completeURL = 'v1/identity/complete'

export const getCompleteOptions = ({
  config,
  mutationOptions
}: CompleteUseMutationProps): CompleteMutationOption => {
  const { APIGEE_CLIENT_ID } = config
  // override to use an actual auth server on local instead of sandbox for realistic dev
  const apiHostToUse = getApiHostforAuthService(config)

  return {
    // 1 key since each login overwrites the previous fetch
    mutationKey: [completeURL],
    mutationFn: async () => {
      const headers: HeadersInit = {
        'X-SYF-Client-Id': APIGEE_CLIENT_ID,
        // randomly generated hex value for tracking each request
        'X-SYF-Request-TrackingId': getTransId(),
        // the interaction id from /introspect
        'X-SYF-Auth-Session-Id': getSessionID(),
        'X-SYF-Request-CorrelationId': getCorrelationId()
      }

      const requestConfig: RequestInit = {
        method: 'GET',
        headers
      }

      const fetchOptions = {
        baseURL: apiHostToUse,
        init: requestConfig
      }
      // log authentication complete start with headers for better debugging auth flow
      setNewRelicPageAction(
        `Start ${requestConfig.method} ${completeURL}`,
        headers
      )
      // fetchBasic because this is an auth call so no auth headers available yet
      return fetchBasic<CompleteMutationData>(completeURL, fetchOptions)
    },
    useErrorBoundary: true,
    ...mutationOptions
  }
}
