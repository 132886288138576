import { ReactElement, useRef } from 'react'
import { i18n } from 'strings/i18n'
import {
  ModalFooter,
  ModalHeader,
  SlideInModalPortal
} from 'syf-component-library/ui/patterns/SlideInModalPortal'
import type { ModalProps } from './Modal.types'

const Modal = ({
  children,
  containerId,
  handleClose,
  headerTitle,
  hideCloseIcon = true,
  id,
  isOpen
}: ModalProps): ReactElement => {
  const headerRef = useRef(null)

  return (
    <SlideInModalPortal
      id={id}
      containerId={containerId}
      escapeModalHandler={handleClose}
      isOpen={isOpen}
      header={
        <ModalHeader
          headerRef={headerRef}
          content={headerTitle}
          hideClose={hideCloseIcon}
          closeModal={handleClose}
        />
      }
      footer={
        <ModalFooter
          handlePrimaryButton={handleClose}
          primaryText={i18n({ buttons: 'close' })}
        />
      }
    >
      {children}
    </SlideInModalPortal>
  )
}

export default Modal
