import styled from 'styled-components'
import { spacing } from 'syf-component-library/defaultTheme'

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${spacing.xxlarge}px auto;
  flex-direction: column;
`

export default PageContainer
