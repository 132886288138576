import { ProfileData } from './mockProfileData.types'

export const mockProfileData: ProfileData = {
  profile: {
    'cipher.firstName': 'TEST',
    'cipher.lastName': 'FISERV',
    'cipher.annualNetIncome': '4444040',
    'cipher.email': 'RAMAN.REDDY@SYF.COM',
    mailingAddress: {
      'cipher.addressLine1': '4125 WINDWARD PLZ',
      'cipher.addressLine2': '',
      'cipher.city': 'ALPHARETTA',
      'cipher.state': 'GA',
      'cipher.zipCode': '30005',
      'cipher.yearsAtAddress': '0'
    },
    phone: [
      {
        contactType: 'HOME',
        'cipher.contactNumber': '9346592038'
      },
      {
        contactType: 'BUSINESS',
        'cipher.contactNumber': '3984653902'
      },
      {
        contactType: 'MOBILE',
        'cipher.contactNumber': '2938562109'
      }
    ]
  },
  eligibility: true
}
