// These are the IDs that some MFE require as part of their configuration properties.

export const makeAPaymentModalContainerId = (guid: string): string =>
  `make-a-payment-modal-containerId${guid}`

export const accountDetailsModalContainerId = (guid: string): string =>
  `account-details-modal-containerId${guid}`

export const activityModalContainerId = (guid: string): string =>
  `activity-modal-containerId${guid}`

export const errorModalContainerId = () => 'error-modal-modal-container-id'

export const mfeErrorModalContainerId = () =>
  'mfe-error-modal-modal-container-id'
