import { NonVisibleText } from './ScreenReaderOnly.styles'
import { ScreenReaderOnlyProps } from './ScreenReaderOnly.types'

/**
 *  This is a visually hidden text component
 *  which make the content invisible for screen
 *  reader users. Ref: https://webaim.org/techniques/css/invisiblecontent/
 */

const ScreenReaderOnly = ({ text }: ScreenReaderOnlyProps) => (
  <NonVisibleText>{text}</NonVisibleText>
)

export default ScreenReaderOnly
