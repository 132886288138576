import config from 'const/config'
import { createGlobalStyle } from 'styled-components'

/*
 * prevents ts-styled-plugin linter throwsing a false positive error
 * open issue https://github.com/microsoft/typescript-styled-plugin/issues/100
 */
const assetHost = `${config.ASSETS_HOST}`

const globalFont = `
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
      url("${assetHost}fonts/Bold/OpenSans-Bold.woff") format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
      url("${assetHost}fonts/Regular/OpenSans-Regular.woff") format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-display: fallback;
    src: local('Open Sans Light'), local('OpenSans-Light'),
      url("${assetHost}fonts/Light/OpenSans-Light.woff") format('woff');
  }

  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
    src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
      url("${assetHost}fonts/Semibold/OpenSans-Semibold.woff") format('woff');
  }
`

/**
 * App styling that sets
 * font, basic styles and background image.
 */

export const GlobalAppStyles = createGlobalStyle`
  ${globalFont}

  body {
    background: ${({ theme }) => theme.body.background};
    background-attachment: ${({ theme }) => theme.body.backgroundAttachment};
    box-sizing: border-box;
    color: ${({ theme }) => theme.body.text};
    font-family: 'Open Sans';
    margin: 0;
  }
`
