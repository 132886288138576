import { ReactElement, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-regular-svg-icons'
import InactivityTimer from 'syf-component-library/ui/templates/InactivityTimer'
import { i18n } from 'strings/i18n'
import handleLogout from 'helpers/handleLogout'
import { useUser } from 'hooks'
import type { InactivityModalProps } from './InactivityModal.types'
import { AlwaysOnTop } from './InactivityModal.styles'

// how often in milliseconds the reset inactive timer function can be run, useful to reduce cpu load
const THROTTLING_MS = 50
const SECONDS_PER_MINUTE = 60
// auth session expires at 30 minutes so this should be less than that
const INACTIVE_MINUTES_LIMIT = 15
const INACTIVE_SECONDS_LIMIT = INACTIVE_MINUTES_LIMIT * SECONDS_PER_MINUTE

const InactivityModal = ({
  timeLimit = INACTIVE_SECONDS_LIMIT
}: InactivityModalProps): ReactElement => {
  const { isLoggedIn } = useUser()
  // this ref gets attached to h2 in the modal popup for focus management
  const headerRef = useRef()

  const modalTextProp = {
    continueButton: `${i18n({ inactivityModal: 'continueButton' })}`,
    countdownWarning: `${i18n({ inactivityModal: 'countdownWarning' })}`,
    modalTitle: `${i18n({ inactivityModal: 'modalTitle' })}`,
    promptTitle: `${i18n({ inactivityModal: 'promptTitle' })}`,
    seconds: `${i18n({ inactivityModal: 'seconds' })}`,
    signOffButton: `${i18n({ inactivityModal: 'logOffButton' })}`
  }

  const handleExtendSession = () => {
    // call the extend session api when it exists
  }

  return (
    <AlwaysOnTop>
      <InactivityTimer
        expiryTime={timeLimit}
        headerRef={headerRef}
        icon={<FontAwesomeIcon icon={faClock} size="4x" />}
        isLoggedIn={isLoggedIn}
        logoutHandler={handleLogout}
        messageType="session timeout"
        newActivityHandler={handleExtendSession}
        text={modalTextProp}
        throttleTimer={THROTTLING_MS}
      />
    </AlwaysOnTop>
  )
}

export default InactivityModal
