import styled from 'styled-components'
import {
  colors,
  fontSizes,
  lineHeights,
  spacing
} from 'syf-component-library/defaultTheme'
import { Card } from 'syf-component-library/ui/atoms'
import { H2 } from 'syf-component-library/ui/typography'
import query from 'syf-component-library/const/mediaQueries'

export const LoginCardContainer = styled(Card)`
  margin: 0 ${spacing.base}px ${spacing.xxlarge * 2}px ${spacing.base}px;

  @media ${query.greaterThanSmall} {
    max-width: 375px; // for spanish to fit
    margin-top: ${spacing.xlarge}px;
    height: fit-content; // for the container's height to be set based on it's content (fixes an issue with the bottom border radius not showing on larger screens)
    border: none;
    box-shadow: none;
  }
`
export const LoginCardContent = styled.div`
  padding: ${spacing.large}px;
  background-color: ${colors.white};

  & .sign-in-error {
    height: ${spacing.base}px;
  }

  & .red-placeholder + label {
    color: ${colors.error};
  }
`

export const LoginHeader = styled(H2)`
  /** Using styles from H5 for semantic purposes*/
  font-size: ${fontSizes.medium}px;
  line-height: ${lineHeights.larger};
`
