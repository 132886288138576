export interface DefaultAnalyticsObject extends Record<string, string> {
  ClientName?: string
  // eslint-disable-next-line camelcase
  Country_Language?: string
  CustomerType?: string
  ErrorMessage?: string
  MessageType?: string
  PageFunction?: string
  PageKind?: string
  pageName?: string
  PageSubFunction?: string
  PNL?: string
  Product?: string
  SiteIdentifier?: string
  Token?: string
}

export interface AnalyticsEvent extends DefaultAnalyticsObject {
  numberofaccounts?: string
}

export const SFDDLPageInfoProps = [
  'ClientName',
  'Country_Language',
  'CustomerType',
  'ErrorMessage',
  'MessageType',
  'PageFunction',
  'PageKind',
  'pageName',
  'PageSubFunction',
  'PNL',
  'Product',
  'SiteIdentifier',
  'Token',
  'numberofaccounts'
]
export const PAGE_INFO_PROPS = {
  ClientName: 'ClientName',
  Country_Language: 'Country_Language',
  CustomerType: 'CustomerType',
  ErrorMessage: 'ErrorMessage',
  MessageType: 'MessageType',
  PageFunction: 'PageFunction',
  PageKind: 'PageKind',
  pageName: 'pageName',
  PageSubFunction: 'PageSubFunction',
  PNL: 'PNL',
  Product: 'Product',
  SiteIdentifier: 'SiteIdentifier',
  Token: 'Token',
  numberofaccounts: 'numberofaccounts'
} as const
