import { Suspense, lazy } from 'react'
import AuthorizedSignedInCheck from 'ui/molecules/AuthorizedSignedInCheck'
import QueryParamsCheck from 'ui/molecules/QueryParamsCheck'
import LoadingView from 'ui/molecules/LoadingView'
import { useFeatures } from 'flagged'
import type { RouteEnabledCheckProps } from './RouteEnabledCheck.types'

const MaintenancePage = lazy(
  () =>
    import(
      /* webpackChunkName: "pg-not-found" */
      'ui/pages/MaintenancePage'
    )
)

/**
 * Component to render a route based on a feature flag value
 *
 * @param {RouteEnabledCheckProps} props - Component props containing:
 * @param {ReactElement} props.children - what to render if the feature flag is enabled
 * @param {string} props.featureFlag - feature flag name for the route
 * @param {AuthFlowType} props.authFlow - The specific type of signin page and flow user will be directed to
 * @returns {Route} - Route element to render
 */

const RouteEnabledCheck = ({
  children,
  featureFlag,
  authFlow
}: RouteEnabledCheckProps) => {
  const featureFlags = useFeatures()
  const isFeatureFlagEnabled = featureFlags[featureFlag]

  return isFeatureFlagEnabled ? (
    <QueryParamsCheck authFlow={authFlow}>
      <AuthorizedSignedInCheck authFlow={authFlow}>
        {children}
      </AuthorizedSignedInCheck>
    </QueryParamsCheck>
  ) : (
    <Suspense fallback={<LoadingView showText={false} />}>
      <MaintenancePage />
    </Suspense>
  )
}

export default RouteEnabledCheck
