import { SYF_HOME_BASE_URL } from 'const/constants'
import { getSFFDLProp } from 'helpers/analytics/SFDDLHelpers'
import { useUser } from 'hooks'
import { ReactElement } from 'react'
import { i18n } from 'strings/i18n'
import CLFooter from 'syf-component-library/ui/patterns/Footer'

const Footer = (): ReactElement => {
  // force footer to rerender on language change, that's stored in the user context
  useUser()

  const footerLinks = [
    {
      text: `\u00A9 ${new Date().getFullYear()} Synchrony `,
      url: `${SYF_HOME_BASE_URL}`,
      dataType: 'footer',
      dataReason: '2021 synchrony'
    },
    {
      text: i18n({ footer: 'privacyPolicy' }),
      url: `${SYF_HOME_BASE_URL}${i18n({ footer: 'privacyPolicyLink' })}`,
      dataType: 'footer',
      dataReason: 'Online privacy policy'
    },
    {
      text: i18n({ footer: 'usageAgreement' }),
      url: `${SYF_HOME_BASE_URL}${i18n({
        footer: 'usageAgreementLink'
      })}`,
      dataType: 'footer',
      dataReason: 'Online usage'
    },
    {
      text: i18n({ footer: 'fraudProtection' }),
      url: `${SYF_HOME_BASE_URL}${i18n({
        footer: 'fraudProtectionlink'
      })}`,
      dataType: 'footer',
      dataReason: 'Fraud protection'
    }
  ]

  return (
    <CLFooter
      body={i18n({ footer: 'body' })}
      linkList={footerLinks}
      showCobrowse
      cobrowseDataType={getSFFDLProp('SiteIdentifier')}
    />
  )
}

export default Footer
