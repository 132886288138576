import { useContext } from 'react'
import { UserContext, UserContextProps } from 'context/userContext'

/**
 * Get the values in the User context provider:
 * isLoggedIn, updateUser, givenName, setGivenName, currentLanguage, setCurrentLanguage, accountIds, updateAccountIds
 */
const useUser = (): UserContextProps => {
  const currentContext = useContext(UserContext)
  if (currentContext === undefined) {
    throw new Error(
      'UserContext used outside of its Provider, make sure you are using this in a child component of the Provider'
    )
  }
  return currentContext
}

export default useUser
