import type { AnyObject } from 'syf-js-utilities'
import { featureFlagsKey } from 'const/flags/globals'
import defaultFlags from 'const/flags/defaultFlags'
import isJSON from 'helpers/isJSON'
import type { FlagsQueryData } from 'services/flagsQuery/flagsQuery.types'

/**
 * Gets the feature flags data object from local storage if it exists
 * falling back to the default feature flags if it does not.
 * @returns {object} FlagsQueryData object with feature flag values
 */
const getFeatureFlagsInStore = (): AnyObject => {
  const storedFeatureFlags = localStorage.getItem(featureFlagsKey)
  const isValidJson = isJSON(storedFeatureFlags)
  if (storedFeatureFlags && isValidJson) {
    const flagsData: FlagsQueryData = JSON.parse(storedFeatureFlags)
    return flagsData.flags
  }
  return defaultFlags.flags
}

export default getFeatureFlagsInStore
