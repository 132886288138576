import { Preferences } from './mockPreferencesData.types'

export const mockPreferencesData: Preferences = {
  preferences: [
    {
      id: 'AUTOPAY_ENROLL',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'AUTOPAY_CANCEL',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_AUTOPAY_REMINDER',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_ACCOUNT_SUMMARY',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'AUTOPAY_MODIFY',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_PAYMENT_RETURNED',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_PAYMENT_REMINDER',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'PAYMENT_POSTED',
      delivery: {
        email: {
          enrolled: false
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'PAYMENT_SCHEDULED',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_LOAN_COMPLETE',
      delivery: {
        email: {
          enrolled: true
        },
        text: {
          enrolled: false
        },
        push: {
          enrolled: false
        },
        clientNotification: {
          enrolled: false
        }
      }
    }
  ],
  smsConsent: false
}
