/**
 * Sets up an interval loop to check every 100 ms whether a condition has been matched.
 * If so, it will execute the callback, otherwise it will keep checking up to the number
 * of maxTries, then it will execute the callback and end the interval loop.
 * @param testIfReadyFn {function} test function executed every interval that returns true when callback should be run
 * @param callback {function} function to run if the test passes or we max out # of tries
 * @param maxTries {integer} maximum number of times to run the interval until we are forced to execute the callback.
 * default is 10 times which assuming 100ms delay = waiting up to 1s for the test to be true
 */
const waitToRun = (
  testIfReadyFn: () => boolean,
  callback: () => unknown,
  maxTries = 10
): void => {
  // how long to wait in between checks in ms
  const delay = 100
  let tries = 0

  const intervalID = window.setInterval(() => {
    // we can run the callback if test succeeds or we max out tries
    if (tries === maxTries || testIfReadyFn()) {
      callback()
      // cancel the interval if we're done to free resources
      window.clearInterval(intervalID)
    }
    tries++
  }, delay)
}

export default waitToRun
