import { UseQueryResult, useQuery } from '@tanstack/react-query'

import { useFetch } from 'syf-api'
import type HTTPError from 'syf-js-utilities/typings/HTTPError'
import type { MFEConfig } from 'syf-js-utilities/typings/mfeConfig'

export interface TestQueryData {
  /** Whether the service succeeded or failed */
  status: string
}

export interface TestQueryProps {
  /** MFE config object passed by all apps with general settings */
  config: MFEConfig
  /** Custom id for the test query cache key if you want to refetch something different */
  cacheKeyId?: string
  /** Whether to tell the test api to return an error status code */
  error?: boolean
}

export const testEndpointUrl = 'test'
// the react-query cache key to store test response under
const makeQueryKey = (id = '0') => ['test', id]

/**
 * Makes a call to the /test endpoint which is just for simple fetch tests
 * in real network conditions, can return a simple success status or an error.
 * @param props TestQueryProps - options for the test query TestQueryProps
 * @returns the same as useQuery returns
 */
export const useTestQuery = (
  props: TestQueryProps
): UseQueryResult<TestQueryData, HTTPError> => {
  const { config, error = false, cacheKeyId } = props
  const fetchWrapper = useFetch(config)
  return useQuery<TestQueryData, HTTPError>({
    queryKey: makeQueryKey(cacheKeyId),
    queryFn: () => {
      const urlParameters = new URLSearchParams({
        /** Whether to purposely return an error response. */
        error: error.toString()
      })

      return fetchWrapper(`${testEndpointUrl}?${urlParameters}`, {
        method: 'GET'
      })
    },
    // disable automatic fetch of this query
    enabled: false,
    // so errors in the response trigger the nearest react error boundary
    useErrorBoundary: true
  })
}

export default useTestQuery
