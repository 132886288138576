/**
 * Gets an item in sessionStorage with our app's namespace prefix.
 * @param key the name of the item to retrieve
 * @param prefix? prefix to add to key for this app for namespacing our app's session storage, default is set in const/session
 * @returns str/null - The value of the item in sessionStorage
 */
const getAppSessionItem = (key: string, prefix = ''): string =>
  sessionStorage.getItem(`${prefix}${key}`) ?? ''

export default getAppSessionItem
