const AUTHENTICATION_ID = 'authentication-id'
const CUSTOM_MOCKS_ID = 'custom-mock-data-id'
const ERRORS_ID = 'errors-id'
const PAYMENTS_ID = 'payments-id'
const UNIVERSAL_ACTIVATION_ID = 'universal-activation-id'

const CARDS_LIMIT_DEFAULT_VALUE = '3'

export {
  AUTHENTICATION_ID,
  CARDS_LIMIT_DEFAULT_VALUE,
  CUSTOM_MOCKS_ID,
  ERRORS_ID,
  PAYMENTS_ID,
  UNIVERSAL_ACTIVATION_ID
}
