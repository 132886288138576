import { getLanguageInLocalStorage } from './language'

/**
 * Returns an SFDDL friendly string using the language stored in LocalStorage.
 * Format matches SFDDL.Country_Language, which is 'us|[language]' where language = en | es
 */
const getCountryLanguage = (): string => {
  const language = getLanguageInLocalStorage()
  return `us|${language}`
}

export default getCountryLanguage
