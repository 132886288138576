import { EVENTS } from 'const/orchestratorMachine'
import {
  EVENTS as ACCOUNT_CARD_EVENTS,
  STATES as ACCOUNT_CARD_STATES
} from 'const/accountCardMachine'
import { OrchestratorContext } from './Orchestrator'
import { AccountCardLoadingContext } from './AccountCardMachine'
import type {
  AccountCardLoadingPayload,
  OpenMFEEvent,
  OrchestratorHelpers
} from './Orchestrator.types'

const useOrchestratorHelpers = (): OrchestratorHelpers => {
  const [, orchestratorSend] = OrchestratorContext.useActor()
  const [accountCardLoadingState, accountCardLoadingSend] =
    AccountCardLoadingContext.useActor()

  return {
    openMFE: (mfeToToggle: OpenMFEEvent, accountId: string) => {
      orchestratorSend({ type: mfeToToggle, accountId })
    },
    openMockTriggers: () => {
      orchestratorSend(EVENTS.OPEN_MOCK_TRIGGERS)
    },
    closeMFE: () => {
      orchestratorSend(EVENTS.CLOSE_MFE)
    },
    setAccountCardLoading: (accountCardLoading: AccountCardLoadingPayload) => {
      accountCardLoadingSend({
        type: ACCOUNT_CARD_EVENTS.CHANGE_CARD_LOADING_STATE,
        accountCardLoading
      })
    },
    getIsAccountCardsLoading: () => {
      return accountCardLoadingState.matches(ACCOUNT_CARD_STATES.LOADING)
    }
  }
}

export default useOrchestratorHelpers
