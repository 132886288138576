import { tokenVerifier } from 'const/session'
import { getAppSessionItem } from 'helpers/appSessionItem'
import getSessionAuthFlow from 'helpers/authFlows/getSessionAuthFlow'
import { getSessionAccessToken } from 'helpers/authUtils'
import { TokenVerifier } from 'typings/TokenVerifier'

/**
 * Checks if an existing saved access token in session storage is
 * valid for use in our app, for the current account type's sign in flow
 * or if it's an access token for a different auth flow or syf app.
 * @returns {boolean}
 */
const checkStoredToken = (): boolean => {
  const sessionTokenVerifier = getAppSessionItem(tokenVerifier)
  const sessionAccessToken = getSessionAccessToken()

  if (!sessionAccessToken || !sessionTokenVerifier) {
    return false
  }

  // catch empty or invalid json strings for json.parse errors
  try {
    const tokenVerifierData = JSON.parse(sessionTokenVerifier) as TokenVerifier
    const { token, authFlow } = tokenVerifierData
    /*
     * since we don't have runtime type checks, this guards against
     * empty or incomplete verifier objects: {}, {token: 'abc'}
     */
    if (!token || !authFlow) {
      throw new Error('Incomplete token verifier data!')
    }

    const isMatchingToken = token === getSessionAccessToken()
    const isMatchingAuthFlow = authFlow === getSessionAuthFlow()

    return isMatchingToken && isMatchingAuthFlow
  } catch (e) {
    return false
  }
}

export default checkStoredToken
