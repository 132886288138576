import triggerAnalyticsEvent from 'syf-js-utilities/helpers/triggerAnalyticsEvent'
import getLanguageFromStorage from 'syf-js-utilities/helpers/getLanguageFromStorage'
import { getSessionID } from './authUtils'
import { setAnalyticsConfigDefaultProp } from './analytics/SFDDLHelpers'
import { PAGE_INFO_PROPS } from './analytics/analytics.types'

if (navigator.cookieEnabled) {
  setAnalyticsConfigDefaultProp(
    PAGE_INFO_PROPS.Country_Language,
    `us|${getLanguageFromStorage()}`
  )
  setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.Token, getSessionID())
}

setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.ClientName, 'generic')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.CustomerType, 'consumer')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.ErrorMessage, '')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.MessageType, '')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.PageFunction, 'quickaccess')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.PageKind, '')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.pageName, '')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.PageSubFunction, '')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.PNL, 'peaks')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.Product, 'generic')
setAnalyticsConfigDefaultProp(PAGE_INFO_PROPS.SiteIdentifier, 'peaks')

export default triggerAnalyticsEvent
