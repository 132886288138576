import type ResponseLinkItem from 'typings/ResponseLinkItem'

export interface ResponseWithLinks {
  links: ResponseLinkItem[]
}

/**
 * For a given response json that contains a links property, this
 * function will extract the first valid URL value from that links array.
 * @param response obj - a json object containing a links array
 * @returns str - the url of the first link item
 */
function getLinkInResponse<T extends ResponseWithLinks>(
  response: T
): URL['href'] {
  const { links = [] } = response
  // using find prevents issues with hardcoded references to indexes like malformed arrays
  const theFirstValidItem = (current: ResponseLinkItem | undefined) =>
    current !== undefined
  const firstItem = links.find(theFirstValidItem)
  return firstItem?.href ?? ''
}

export default getLinkInResponse
