import routes from 'const/routes'
import { useNavigate } from 'react-router-dom'

interface ReturnHeaderNavigation {
  goToAlerts: () => void
  goToDashboard: () => void
}

const useHeaderNavigation = (): ReturnHeaderNavigation => {
  const navigate = useNavigate()

  const goToAlerts = () => {
    navigate(routes.ALERTS)
  }

  const goToDashboard = () => {
    navigate(routes.DASHBOARD)
  }

  return {
    goToAlerts,
    goToDashboard
  }
}

export default useHeaderNavigation
