/**
 * Checks if a string is valid JSON or not.
 * @param data {string} - a string to check if parseable JSON
 * @returns {boolean} - whether it is JSON or not
 */
const isJSON = (data: string): boolean => {
  try {
    const parsedJSON = JSON.parse(data)
    if (parsedJSON === null || typeof parsedJSON !== 'object') {
      throw new Error('Invalid JSON object')
    }
    return true
  } catch (e) {
    return false
  }
}

export default isJSON
