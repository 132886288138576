import {
  ReactElement,
  createContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSettings } from 'hooks'
import * as toggles from 'mocks/ui/const/toggles'
import mswBrowser, { startMSW, stopMSW } from 'mocks/mswBrowser'
import { TOGGLE_STRINGS } from 'mocks/ui/molecules/ToggleMockCheckbox/ToggleMockCheckbox.constants'
import { RestHandler } from 'msw'
import type {
  MockTogglesProviderProps,
  MockTogglesState
} from './MockTogglesContext.types'

export const MockTogglesContext = createContext<MockTogglesState | undefined>(
  undefined
)

/**
 * MockToggles stores data on which mock toggles are enabled across the app and provides
 * an updater interface for that state in react/session storage/msw.
 * Wraps passed children components in the MockToggles context so they can access
 * the values set in the provider or update those state values.
 * @param props {children: jsx elements to wrap the provider around}
 * @returns context provider wrapped components
 */
const MockTogglesProvider = ({
  children
}: MockTogglesProviderProps): ReactElement => {
  const [mockTogglesEnabled, setMockTogglesEnabled] = useState([])
  const { isMockDataEnabled } = useSettings()

  const { ON, OFF } = TOGGLE_STRINGS

  // gets the session storage keys for each mock toggle
  const getToggleKeys = () => Object.values(toggles)

  // normalizes handlers to always an array
  const normalizeOverrideHandlers = (
    mswOverrideHandlers: RestHandler | RestHandler[]
  ) => {
    // narrow the type to either single item or multiple args passed
    const isNotHandlerArray = !Array.isArray(mswOverrideHandlers)
    // ensure we always get an array in the merge function
    return isNotHandlerArray ? [mswOverrideHandlers] : mswOverrideHandlers
  }

  // use msw error handler so we get mock error data if enabling otherwise reset msw to defaults
  const updateMSWHandlers: MockTogglesState['updateMSWHandlers'] = (
    mswOverrideHandlers
  ) => {
    if (!mswBrowser) {
      return
    }
    // start from a blank slate
    mswBrowser.resetHandlers()
    // if no handlers were passed, we're just resetting to default
    if (!mswOverrideHandlers) {
      return
    }
    // ensure we always get an array in the merge function
    const overrideHandlers = normalizeOverrideHandlers(mswOverrideHandlers)
    // overrides whatever default handelrs share the same url with the override handlers
    mswBrowser.use(...overrideHandlers)
    mswBrowser.printHandlers()
    stopMSW()
    startMSW()
  }

  // for erasing all the mock toggles in session storage
  const clearSessionMockToggles = () => {
    const toggleKeys = getToggleKeys()
    toggleKeys.forEach((currentToggle) => {
      sessionStorage.removeItem(currentToggle)
    })
  }

  // updater that enable or disables a toggle, ensuring only 1 is active at a time in all stores
  const switchMockToggles: MockTogglesState['switchMockToggles'] = (
    name,
    mswOverrideHandlers
  ) => {
    const sessionMockSetting = sessionStorage.getItem(name)
    const isMockSettingOn = sessionMockSetting === ON

    // set the new session storage value to the opposite of the current value to toggle it
    const newSessionValue = isMockSettingOn ? OFF : ON
    const newStateValue = isMockSettingOn ? [] : [name]
    // remove existing mock toggle settings to only allow one mock toggle at a time
    clearSessionMockToggles()
    sessionStorage.setItem(name, newSessionValue)

    setMockTogglesEnabled(newStateValue)

    // override the msw default handlers with the one we want to mock with or reset to default
    if (mswOverrideHandlers && newSessionValue === ON) {
      updateMSWHandlers(mswOverrideHandlers)
    } else {
      updateMSWHandlers()
    }
  }

  useEffect(() => {
    // check if any mock toggle is already on in session storage when loaded
    const anEnabledToggle = (currentKey) =>
      sessionStorage.getItem(currentKey) === ON
    // only the first match bc we only allow 1 mock toggle on at a time
    const alreadyOnToggle = getToggleKeys().find(anEnabledToggle)

    // if so, then turn it on in state
    if (alreadyOnToggle) {
      setMockTogglesEnabled([alreadyOnToggle])
    }
  }, [])

  useEffect(() => {
    // if mock data is disabled, clear all the toggles and corresponding mock handlers
    if (!isMockDataEnabled) {
      getToggleKeys().forEach((currentToggle) =>
        sessionStorage.removeItem(currentToggle)
      )
      setMockTogglesEnabled([])
      mswBrowser.resetHandlers()
    }
  }, [isMockDataEnabled])

  // values of MockToggles and possible setters for those MockToggles
  const MockTogglesState: MockTogglesState = {
    mockTogglesEnabled,
    setMockTogglesEnabled,
    switchMockToggles,
    updateMSWHandlers
  }

  const memoizedValues = useMemo(() => MockTogglesState, [mockTogglesEnabled])

  return (
    <MockTogglesContext.Provider value={memoizedValues}>
      {children}
    </MockTogglesContext.Provider>
  )
}

export default MockTogglesProvider
