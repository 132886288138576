export const STATES = {
  NONE_MFE_IS_OPEN: 'NONE_MFE_IS_OPEN',
  DETAILS_IS_OPEN: 'DETAILS_IS_OPEN',
  ACTIVITY_IS_OPEN: 'ACTIVITY_IS_OPEN',
  MAP_IS_OPEN: 'MAP_IS_OPEN',
  MOCK_TRIGGERS_IS_OPEN: 'MOCK_TRIGGERS_IS_OPEN'
}

export const EVENTS = {
  OPEN_DETAILS: 'OPEN_DETAILS',
  OPEN_ACTIVITY: 'OPEN_ACTIVITY',
  OPEN_MAP: 'OPEN_MAP',
  OPEN_MOCK_TRIGGERS: 'OPEN_MOCK_TRIGGERS',
  CLOSE_MFE: 'CLOSE_MFE'
} as const
