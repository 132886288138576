import { getQueryDataFromCache } from 'helpers/cacheQueries'
import {
  CacheQueriesType,
  ReturnGetQueryDataFromCache
} from 'helpers/cacheQueries.types'

/**
 * Returns an account with its details including from cache
 * @param {string} accountId  An account ids to be used to get its information
 * @returns {ReturnGetQueryDataFromCache} An object containing the details of the account
 */
const useAccountInfo = (accountId: string): ReturnGetQueryDataFromCache => {
  const accountWithDetails: ReturnGetQueryDataFromCache = getQueryDataFromCache(
    CacheQueriesType.SUMMARY,
    {
      accountId
    }
  )

  return accountWithDetails
}

export default useAccountInfo
