import { Component, ErrorInfo, ReactNode } from 'react'
import MFEErrorModal from '../MFEErrorModal/MFEErrorModal'

interface MFEErrorBoundaryProps {
  children: ReactNode
  handleReset: () => void
}

interface MFEErrorBoundaryState {
  hasError: boolean
  /** contains the error information tha we might wanna check against */
  error: Error | null
  isMFEOpen: boolean
}
/**
 * Wrapper component that catches any errors in the component tree. Instead of breaking the app
 * completely, it will render the fallback UI passed.
 */
class MFEErrorBoundary extends Component<
  MFEErrorBoundaryProps,
  MFEErrorBoundaryState
> {
  constructor(props: MFEErrorBoundaryProps) {
    super(props)
    this.handleCloseModal = this.handleCloseModal.bind(this)

    this.state = {
      hasError: false,
      error: null,
      isMFEOpen: false
    }
  }

  static getDerivedStateFromError(error: Error): MFEErrorBoundaryState {
    return { hasError: true, error, isMFEOpen: false }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, isMFEOpen: true })
    console.error('Error boundary:', error, errorInfo)
  }

  handleCloseModal(): void {
    this.setState({ isMFEOpen: false })
    // eslint-disable-next-line react/destructuring-assignment
    this.props.handleReset()
  }

  render(): ReactNode {
    const { hasError, error, isMFEOpen } = this.state
    const { children } = this.props
    if (hasError && error) {
      return (
        <MFEErrorModal
          isMFEOpen={isMFEOpen}
          handleCloseModal={this.handleCloseModal}
        />
      )
    }

    return children
  }
}

export default MFEErrorBoundary
