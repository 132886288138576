import type { AnyObject } from 'syf-js-utilities/typings/AnyObject'
import type { ErrorResponseData } from 'typings/ErrorResponseData'
import {
  ACTIVATION_BUSINESS_ERROR,
  IDENTITY_BUSINESS_ERROR
} from 'const/businessErrors'

/**
 * Checks if the passed object is a business error response.
 * Used for type narrowing functions that can return this json data
 * or null from a response.
 * @param response - the parsed response data (json) coming from a fetch function or a raw response
 * @returns boolean - whether it is ErrorResponseData or not
 */
function isBusinessErrorData(
  response: AnyObject
): response is ErrorResponseData {
  return (
    response?.code === IDENTITY_BUSINESS_ERROR ||
    response?.code === ACTIVATION_BUSINESS_ERROR
  )
}

export default isBusinessErrorData
