import { useEffect } from 'react'
import type { ReactElement } from 'react'
import getImageURL from 'helpers/getImageURL'
import { i18n } from 'strings/i18n'
import Card from 'syf-component-library/ui/atoms/Card/Card'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { analyticsEvents } from 'helpers/analytics/events'
import en from 'strings/lang/en'
import { authFlow } from 'const/session'
import { getAppSessionItem } from 'helpers/appSessionItem'
import type { AuthFlowType } from 'typings/Auth'
import type { ErrorCardProps } from './ErrorCard.types'
import {
  BodyText,
  BodyTextContainer,
  BodyTitle,
  CenterButton,
  ErrorCardBody,
  ErrorCardHeader,
  ErrorCardImage
} from './ErrorCard.styles'

const ErrorCard = ({
  image,
  altText,
  bodyTitle,
  bodyContent,
  centerButtonText,
  centerButtonClick
}: ErrorCardProps): ReactElement => {
  useEffect(() => {
    const bodyTitleText = en.errorComponent[bodyTitle]
    const bodyContentText = en.errorComponent[bodyContent]
    triggerAnalyticsEvent(
      analyticsEvents.error(
        `${bodyTitleText}. ${bodyContentText}`,
        getAppSessionItem(authFlow) as AuthFlowType
      )
    )
  }, [])

  return (
    <Card>
      <ErrorCardHeader>
        <ErrorCardImage
          src={getImageURL(image)}
          alt={i18n({ errorComponent: altText })}
        />
      </ErrorCardHeader>
      <ErrorCardBody>
        <BodyTextContainer>
          <BodyTitle>{i18n({ errorComponent: bodyTitle })}</BodyTitle>
          <BodyText>{i18n({ errorComponent: bodyContent })}</BodyText>
        </BodyTextContainer>
        {centerButtonText && (
          <CenterButton buttonType="primary" onClick={centerButtonClick}>
            {i18n({ errorComponent: centerButtonText })}
          </CenterButton>
        )}
      </ErrorCardBody>
    </Card>
  )
}

export default ErrorCard
