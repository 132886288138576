import {
  CHECK_NON_DIGITS_REGEX,
  FILTER_NON_DIGITS_REGEX,
  INPUT_LIMITS
} from 'const/constants'
import { setAppSessionItem } from 'helpers/appSessionItem'

const isCharNonDigit = (char: string) => CHECK_NON_DIGITS_REGEX.test(char)

const filterNonDigits = (value: string): string =>
  value.replace(FILTER_NON_DIGITS_REGEX, '')

export const filterAccountNumber = (accountNumber: string) =>
  filterNonDigits(accountNumber).substring(0, INPUT_LIMITS.ACCOUNT_NUMBER)

export const filterCreditCard = (creditCard: string) =>
  filterNonDigits(creditCard).substring(0, INPUT_LIMITS.CREDIT_CARD)

export const filterSecurityCode = (securityCode: string) =>
  filterNonDigits(securityCode).substring(0, INPUT_LIMITS.SECURITY_CODE)

export const filterSSN = (ssn: string) =>
  filterNonDigits(ssn).substring(0, INPUT_LIMITS.SSN)

export const filterZipCode = (zipCode: string) =>
  filterNonDigits(zipCode).substring(0, INPUT_LIMITS.ZIP_CODE)

const getCursorOnDigitErased = (
  currCursorIndex: number,
  maskedNewValue: string
): number => {
  let newCursorIndex = currCursorIndex
  let charBeforeNewCursorIndex = maskedNewValue.charAt(newCursorIndex - 1)
  // If newCursorIndex points to a non-digit char, remove 1 until it points to a digit char
  while (isCharNonDigit(charBeforeNewCursorIndex)) {
    newCursorIndex -= 1
    charBeforeNewCursorIndex = maskedNewValue.charAt(newCursorIndex - 1)
  }

  return newCursorIndex
}

const getCursorOnInvalidTyped = (
  maskedPrevValue: string,
  rawNewValue: string,
  currCursorIndex: number
): number => {
  const invalidCharsAdded =
    rawNewValue.split(FILTER_NON_DIGITS_REGEX).length -
    maskedPrevValue.split(FILTER_NON_DIGITS_REGEX).length

  return currCursorIndex - invalidCharsAdded
}

const getCursorOnDigitTyped = (
  maskedPrevValue: string,
  currCursorIndex: number,
  maskedNewValue: string
): number => {
  let newCursorIndex

  const prevSliceBeforeCursor = maskedPrevValue.slice(0, currCursorIndex - 1)
  const prevSliceNonDigitCount = prevSliceBeforeCursor
    .split('')
    .filter((char) => isCharNonDigit(char)).length

  const newSliceBeforeCursor = maskedNewValue.slice(0, currCursorIndex)
  const newSliceNonDigitCount = newSliceBeforeCursor
    .split('')
    .filter((char) => isCharNonDigit(char)).length

  const nonDigitDifference = newSliceNonDigitCount - prevSliceNonDigitCount

  newCursorIndex = currCursorIndex + nonDigitDifference

  // If newCursorIndex points to a non-digit char, add 1 until it points to a digit char
  while (isCharNonDigit(maskedNewValue.charAt(newCursorIndex - 1))) {
    newCursorIndex += 1
  }

  return newCursorIndex
}

/**
 * Calculates the new cursor position after a change inside an input field, considering the masking for that input
 * @param prevValue Input value before change
 * @param currCursorIndex Cursor index right after the change in the input, before the the new value gets filtered and mask is applied
 * @param rawNewValue Input value right after the change in the input, before the the new value gets filtered and mask is applied
 * @param maskValue Function for masking a given input value
 * @returns A new cursor index position after considering filters and masking of the new value
 */
export const getNewCursorIndex = (
  prevValue: string,
  currCursorIndex: number,
  rawNewValue: string,
  maskValue?: (str: string) => string
): number => {
  const newValue = filterNonDigits(rawNewValue)
  const maskedNewValue = maskValue ? maskValue(newValue) : newValue
  const maskedPrevValue = maskValue ? maskValue(prevValue) : prevValue

  // If user erased
  if (rawNewValue.length < maskedPrevValue.length) {
    return getCursorOnDigitErased(currCursorIndex, maskedNewValue)
  }

  // If invalid char typed by user, no change in value
  if (maskedPrevValue === maskedNewValue) {
    return getCursorOnInvalidTyped(
      maskedPrevValue,
      rawNewValue,
      currCursorIndex
    )
  }

  return getCursorOnDigitTyped(maskedPrevValue, currCursorIndex, maskedNewValue)
}

export const isValidLength = (value: string, length: number): boolean =>
  value.length === length

export const doesValueExistInObj = (
  object: Record<string, unknown>,
  value: string
): boolean => {
  return Object.values(object).includes(value)
}

export const refreshChunk = async (
  componentImport,
  chunkName: string
): Promise<never> =>
  new Promise((resolve, reject) => {
    const storageKey = `refreshed-${chunkName}`
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || 'false'
    )
    componentImport()
      .then((component) => {
        if (component === undefined) {
          if (window.sessionStorage.getItem(storageKey) === 'true') {
            resolve(undefined)
          }
          setAppSessionItem(storageKey, 'true')
          window.location.reload() // refresh the page
        }
        resolve(component)
      })
      .catch((error: object) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true')
          window.location.reload()
        }
        reject(error) // Default error behaviour as already tried refresh
      })
  })
