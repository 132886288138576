import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useState
} from 'react'
import {
  setBodyScrollBlockStyles,
  setBodyScrollUnblockStyles
} from 'helpers/iOSBodyScrollBlock'
import { isMobileSafari } from 'react-device-detect'

/**
 * Defines the two values needed for restoring the body element position when scroll is re-enabled
 */
interface BodyPosition {
  position: string
  top: string
}

export interface iOSBodyScrollBlockerValue {
  enableBodyScroll: () => void
  disableBodyScroll: () => void
}

export const iOSBodyScrollBlockerContext =
  createContext<iOSBodyScrollBlockerValue | null>(null)

/**
 * Provides two functions to either disable the body element scroll in iOS devices or enable it
 *
 * This fixes an issue with iOS devices where a scrollable element inside a scrollable body element triggers both scroll
 * events at the same time.
 *
 * This function is heavily inspired by the body-scroll-lock library
 */
export const IOSBodyScrollBlockerProvider = ({
  children
}: PropsWithChildren): ReactElement => {
  const [previousBodyPosition, setPreviousBodyPosition] =
    useState<BodyPosition>({ top: '', position: 'static' })

  const enableBodyScroll = useCallback(() => {
    if (isMobileSafari) {
      const currentVerticalScroll = -parseInt(document.body.style.top, 10)

      setBodyScrollUnblockStyles(
        previousBodyPosition.position,
        previousBodyPosition.top
      )

      // Restore previous vertical scroll
      window.scrollTo(0, currentVerticalScroll)
    }
  }, [isMobileSafari, previousBodyPosition.top, previousBodyPosition.position])

  const disableBodyScroll = useCallback(() => {
    if (isMobileSafari) {
      setPreviousBodyPosition({
        position: document.body.style.position,
        top: document.body.style.top
      })
      setBodyScrollBlockStyles()
    }
  }, [isMobileSafari, previousBodyPosition.top, previousBodyPosition.position])

  return (
    <iOSBodyScrollBlockerContext.Provider
      value={{ enableBodyScroll, disableBodyScroll }}
    >
      {children}
    </iOSBodyScrollBlockerContext.Provider>
  )
}
