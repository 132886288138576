/**
 * Prepends a slash (/) character to a route string to make it an absolute route
 * @param route {string} relative route
 * @returns {string} absolute route
 */
const prependSlash = (route: string): string => {
  if (route.charAt(0) === '/') {
    return route
  }

  return `/${route}`
}

export default prependSlash
