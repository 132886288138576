import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import {
  fontSizes,
  fontWeights,
  spacing
} from 'syf-component-library/defaultTheme'
import Button from 'syf-component-library/ui/atoms/Button/Button'
import { Body, H2 } from 'syf-component-library/ui/typography'

export const ErrorCardHeader = styled.div`
  background-color: ${({ theme }) => theme.syfYellow};
  width: 100%;

  //mobile
  height: 109px;

  @media ${query.greaterThanMedium} {
    // desktop
    height: 219px;
  }

  @media ${query.betweenMediumLarge} {
    //tablet
    height: 109px;
  }
`

export const ErrorCardImage = styled.img`
  position: absolute;
  transform: translate(-50%);

  // mobile
  top: 1px;
  width: 84%;
  height: 120px;

  @media ${query.greaterThanMedium} {
    //desktop
    top: 3px;
    width: unset;
    height: 240px;
  }
  @media ${query.betweenMediumLarge} {
    // tablet
    width: 84%;
    top: 13px;
    height: 120px;
  }
`

export const ErrorCardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.large}px ${spacing.xxlarge + spacing.base}px;

  gap: ${spacing.xlarge}px;

  @media ${query.lessThanMedium} {
    padding: ${spacing.large}px;
  }
`

export const BodyTextContainer = styled.div`
  display: grid;
  gap: ${spacing.base}px;
  padding: 0;

  @media ${query.lessThanMedium} {
    gap: ${spacing.medium}px;
  }
`

export const BodyTitle = styled(H2)`
  font-size: ${fontSizes.large}px;
  line-height: ${fontSizes.xxLarge}px;
  font-weight: ${fontWeights.bold};
  @media ${query.lessThanMedium} {
    font-size: ${fontSizes.medium}px;
    line-height: ${fontSizes.xLarge}px;
  }
`

export const BodyText = styled(Body)`
  font-size: ${fontSizes.root}px;
`

export const CenterButton = styled(Button)`
  max-width: fit-content;
  @media ${query.lessThanMedium} {
    max-width: unset;
    width: 100%;
  }
`
