import { ReactElement } from 'react'
import { i18n } from 'strings/i18n'
import { SyfLoader } from 'syf-component-library/ui/atoms'
import { ContentWrapper, LoadingText } from './LoadingView.styles'

const LoadingView = ({
  showText = true
}: {
  showText?: boolean
}): ReactElement => (
  <ContentWrapper>
    <SyfLoader size={4.375} />
    {showText && <LoadingText>{i18n({ labels: 'loading' })}</LoadingText>}
  </ContentWrapper>
)

export default LoadingView
