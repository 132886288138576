export const UNIVERSAL_ACTIVATION_AUTH_ERRORS = {
  ACCOUNT_UNSUPPORTED: 'idp.quick-authenticate.account.unsupported',
  BAD_DEVICE: 'idp.quick-authenticate.error.csr3',
  MAX_ATTEMPTS: 'universal-activation.max.authentication.attempts.reached'
}

export const CARD_ACTIVATION_ERRORS = {
  EXTERNAL_STATUS_CODE: '610004',
  INVALID_EXTERNAL_STATUS: '610002',
  AUTH_SHELL: '620005',
  ALREADY_ACTIVATED: '600003'
}
