/**
 * Sets whether dating mocking is enabled in local storage.
 * Pass it the boolean and it'll set it as a string in the mockDataEnabled key.
 * @param enableMockData boolean - whether to enable mock data
 */
const setLocalMockDataEnabled = (enableMockData: boolean): void => {
  localStorage.setItem('mockDataEnabled', enableMockData.toString())
}

export default setLocalMockDataEnabled
