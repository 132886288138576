import { ReactElement, Suspense, lazy } from 'react'
import { useMFEConfigs } from 'hooks'
import useResetMFEErrorKey from 'hooks/useResetMFEErrorKey'
import { MOCK_THROW_MFE } from 'mocks/ui/const/toggles'
import ThrowErr from 'ui/ThrowErr/ThrowErr'
import MFEErrorBoundary from 'ui/molecules/MFEErrorBoundary/MFEErrorBoundary'
import { useOrchestratorState } from 'orchestrator/Orchestrator'
import { refreshChunk } from 'helpers/utils/utils'
/*
 * defer load & code split this to a separate chunk for initial load perf
 * chunk will load when dashboard loads as long as MakeAPayment is rendered in the adapter
 */
// defer load & code split this to a separate chunk for initial load perf
const MakeAPayment = lazy(() =>
  refreshChunk(
    () =>
      import(
        /* webpackChunkName: "mfe-make-a-payment" */
        /* webpackPrefetch: true */
        'make-a-payment'
      ),
    'MAP-mfe'
  )
)

const MapAdapter = (): ReactElement => {
  const { getMAPConfig } = useMFEConfigs()
  const { accountId: selectedAccountId, isMAPOpen } = useOrchestratorState()
  const { resetKey, handleReset } = useResetMFEErrorKey()

  const isAdapterOpen = isMAPOpen && selectedAccountId
  const tempFlagToThrow = sessionStorage.getItem(MOCK_THROW_MFE) === 'true'

  return (
    <MFEErrorBoundary key={resetKey} handleReset={handleReset}>
      <Suspense fallback={<></>}>
        <MakeAPayment {...getMAPConfig()} />
        {isAdapterOpen && tempFlagToThrow && <ThrowErr />}
      </Suspense>
    </MFEErrorBoundary>
  )
}

export default MapAdapter
