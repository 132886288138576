import { ReactElement } from 'react'
import MockToggles from 'mocks/ui/molecules/MockToggles/MockToggles'
import styled from 'styled-components'
import { useOrchestratorState } from 'orchestrator/Orchestrator'
import useOrchestratorHelpers from 'orchestrator/OrchestratorHelpers'
import { errorModalContainerId } from 'const/modalContainerIds'
import Inset from 'syf-component-library/ui/spacing/Inset'
import Modal from '../../../../ui/molecules/Modal'

const StayOnTop = styled.div`
  z-index: 99;
`

const MockErrorModal = (): ReactElement => {
  const { closeMFE } = useOrchestratorHelpers()
  const { isMockTriggersOpen } = useOrchestratorState()
  return (
    <StayOnTop>
      <Modal
        isOpen={isMockTriggersOpen}
        handleClose={() => closeMFE()}
        headerTitle="Mock Errors"
        id="error-modal-modal-id"
        containerId={errorModalContainerId()}
      >
        <Inset all="medium">
          <MockToggles />
        </Inset>
      </Modal>
    </StayOnTop>
  )
}

export default MockErrorModal
