import makeSafeURL from './makeSafeURL'

/**
 * Adds a script element to the <head>, causing the script to get fetched and execute.
 * Use for dynamically loading an external js file for execution.
 * @param url {string url} the full url you are trying to load ie https://syf.com/js/test.js
 * @returns the script dom node appended to the head
 */
const loadHeadScript = (url: Location['href'], id = '') => {
  const js = document.createElement('script')
  const validURL = makeSafeURL(url)
  const isNotJS = !validURL.endsWith('.js')

  if (!isNotJS) {
    js.type = 'text/javascript'
    js.src = validURL
    js.id = id
    document.head.appendChild(js)
    return js
  }
}

export default loadHeadScript
