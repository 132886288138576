export const cardsLimitOnChange = (
  newMockValue: string,
  defaultValue: string
): string => {
  // if new value is not a positive integer, return default value.
  let newValue = newMockValue
  const isValidNumber = /^[1-9]\d*$/.test(newMockValue)

  if (!isValidNumber) {
    newValue = defaultValue
  }

  return newValue
}
