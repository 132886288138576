interface SignInErrorTypeProps {
  SECURED_INSTALLMENTS: string
  PAY_LATER: string
  UNIVERSAL_ACTIVATION: string
}

export const signInErrorTypes: SignInErrorTypeProps = {
  SECURED_INSTALLMENTS: 'idp.secured-login.account.not-found',
  PAY_LATER: 'idp.pii.authenticate.account.not-found',
  UNIVERSAL_ACTIVATION: 'idp.quick-authenticate.card.details.mismatch.error'
}

export const signInErrors = [
  signInErrorTypes.SECURED_INSTALLMENTS,
  signInErrorTypes.PAY_LATER,
  signInErrorTypes.UNIVERSAL_ACTIVATION
]
