import type { ErrorResponseData } from 'typings/ErrorResponseData'
import type ResponseErrorItem from 'typings/ResponseErrorItem'

/**
 * Gets the first error item in the businessErrors array of the business error response.
 * Error items have a code and a message
 * @param responseData (obj) an ErrorResponseData object with a businessErrors array
 * @returns (obj) ResponseErrorItem or undefined if no match found
 */
function getFirstBusinessErrorInResponse(
  responseData: ErrorResponseData
): ResponseErrorItem | undefined {
  const { businessErrors } = responseData
  const theFirstValidItem = (currentItem) => currentItem?.code
  return businessErrors.find(theFirstValidItem)
}

export default getFirstBusinessErrorInResponse
