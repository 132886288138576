import { HTTPDataError } from 'services/helpers/HTTPDataError'
import type FilterKeysOfType from 'typings/FilterKeysOfType'
import {
  compressToNewRelicCharacterLimit,
  makeNewRelicFormattedHeaders,
  setNewRelicPageAction
} from 'helpers/newRelic'
import { NEW_RELIC_MANUAL_EVENT } from 'const/constants'

// eslint-disable-next-line @typescript-eslint/ban-types
export type ResponseFormatMethods = FilterKeysOfType<Response, Function>

export interface FetchBasicConfig {
  // the base domain for the url to fetch ie https://home.com/
  baseURL?: URL['origin'] | URL
  /*
   * whether to throw an error if fetch fails or let the parent handle it
   * if you use this, you'll want to pass a union type as a generic to accurately
   * type check ie fetchBasic<AuthenticateMutationData | Response>(...)
   * and then type narrow the return value
   */
  throwOnError?: boolean
  // formatter function for the raw Response obj ie json, blob, clone, etc
  format?: ResponseFormatMethods
  // initialization config parameters like headers and request method for the fetch request
  init?: RequestInit
}

/**
 * fetchBasic utility function that will make base syf API calls, such as auth calls.
 * Useful for query/mutation fetch functions in react query.
 * Compared to useFetch, this includes minimal defaults and no preset auth headers.
 * @param url (str) the endpoint url to fetch from, not including the root domain (default root is window.location.origin)
 *                  ie https://www.syf.com/[THIS PATH HERE]
 * @param options (obj) includes the base url to start a path with and if errors should trigger a throw
 * @returns response (promise) a promise that resolves with the set response type
 */
// overloads to allow predefining response types if passed
async function fetchBasic<CustomResponseType>(
  url: URL['href'],
  options: FetchBasicConfig
): Promise<CustomResponseType>

// for 204 response status
async function fetchBasic(
  url: URL['href'],
  options: FetchBasicConfig
): Promise<null>

// if not throwing on error, we get the full response returned
async function fetchBasic(
  url: URL['href'],
  options: FetchBasicConfig
): Promise<Response>

async function fetchBasic(
  url: URL['href'],
  options: FetchBasicConfig = {}
): Promise<unknown> {
  const {
    baseURL = window.location.origin,
    throwOnError = true,
    format = 'json',
    init = {}
  } = options
  const fullUrl = new URL(url, baseURL)

  const { headers = {}, method = 'GET', ...rest } = init

  let defaultContentType = 'application/json'
  if (method?.toUpperCase() === 'GET') {
    defaultContentType = ''
  }

  const response = await fetch(fullUrl.href, {
    mode: 'cors',
    method,
    headers: {
      'Content-Type': defaultContentType,
      Accept: defaultContentType,
      ...headers
    },
    ...rest
  })

  // terminate early if there's an issue with the response and throw if we want to catch those
  if (!response.ok && throwOnError) {
    // copy it since the body stream can only be read once
    const errorResponseCopy = response.clone()
    // text() guards against empty responses
    const errorResponseText = await response.text()
    // if empty string then prevent error from json parsing and set it to an empty data obj
    const errorResponseJSONData = errorResponseText
      ? JSON.parse(errorResponseText)
      : {}
    // log the response since throwing can interfere with it showing up in the devtools network tab
    console.error(`Error fetching ${fullUrl}! Response:`, errorResponseJSONData)

    const debugMsg = `${response.status} Error: ${method} ${fullUrl}`
    const debugData = {
      // 255 chars max size allowed for attributes sent to new relic
      response: compressToNewRelicCharacterLimit(errorResponseText),
      source: NEW_RELIC_MANUAL_EVENT,
      ...makeNewRelicFormattedHeaders(headers)
    }
    /*
     * report as page action also since notice error doesnt work
     * specifically when this fn called in the queryFn
     */
    setNewRelicPageAction(debugMsg, debugData)

    throw new HTTPDataError(errorResponseCopy, errorResponseJSONData)
  } else if (!response.ok) {
    // return the full response for custom error handling
    return response
  }

  // no content http status code
  if (response.status === 204) {
    return null
  }

  return response[format]()
}

export default fetchBasic
