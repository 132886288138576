import { Alerts } from './mockAlertsData.types'

const cancelsAutoPay =
  'Alert will be triggered when user cancels the autopay option'
const userSpecifiedDayValue = 'User specified days preference value'
const userSpecifiedAmountValue = 'user specified amount preference value'

export const mockAlertData: Alerts = {
  alerts: [
    {
      id: 'INSTALLMENT_CREDIT_PAYMENT_REMINDER',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_AUTOPAY_REMINDER',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_PAYMENT_RETURNED',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_ACCOUNT_SUMMARY',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'PAYMENT_POSTED',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'AUTOPAY_CANCEL',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'AUTOPAY_ENROLL',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'AUTOPAY_MODIFY',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'AUTO_RECURRING_CANCELED',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'INSTALLMENT_CREDIT_LOAN_COMPLETE',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'PAYMENT_SCHEDULED',
      description: cancelsAutoPay,
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'AUTOPAY_ENROLL',
      description:
        'Alert will be triggered when user enrolls the autopay option',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'AUTOPAY_MODIFY',
      description:
        'Alert will be triggered when user modifies the autopay option',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'AUTOPAY_REMINDER',
      description: 'user will be sent out a reminder for autopay',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      },
      attributes: {
        reminderDueDays: {
          type: 'NUMBER',
          description: userSpecifiedDayValue,
          changeable: true,
          value: '5'
        }
      }
    },
    {
      id: 'PAYMENT_CANCELED',
      description: 'Alert will be triggered when user cancels the payment',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PAYMENT_PAST_DUE',
      description: 'Alert will be sent when the amount to be paid got over due',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PAYMENT_POSTED',
      description: 'user will get notification when payment gets posted',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PAYMENT_REMINDER',
      description: 'user will be sent out a reminder for payment',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      },
      attributes: {
        reminderDueDays: {
          type: 'NUMBER',
          description: userSpecifiedDayValue,
          changeable: true,
          value: '5'
        }
      }
    },
    {
      id: 'PAYMENT_SCHEDULED',
      description:
        'user will be notified if the payment is scheduled for an account',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PROFILE_CHANGE_ADDRESS',
      description: 'Alert will be triggered when user changes the address',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PROFILE_CHANGE_EMAIL',
      description: 'Alert will be triggered when user changes the email',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PROFILE_CHANGE_NAME',
      description: 'Alert will be triggered when user changes the name',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PROFILE_CHANGE_PHONE',
      description: 'Alert will be triggered when user changes the phone option',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'GENEROUS_TIP',
      description: 'A tip exceeds this percentage on a transaction:',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      },
      attributes: {
        thresholdPercentage: {
          type: 'NUMBER',
          description: userSpecifiedAmountValue,
          changeable: true
        }
      }
    },
    {
      id: 'AVAILABLE_CREDIT',
      description: 'credit balance will be sent to the user',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      },
      attributes: {
        availableCreditThresholdAmount: {
          type: 'NUMBER',
          description: userSpecifiedAmountValue,
          changeable: true
        }
      }
    },
    {
      id: 'BALANCE_OVERLIMIT',
      description:
        'when user attempts to make purchases more than limit,then the transaction will be declined',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'TRANSACTION_DECLINE',
      description: 'user will be notified when transaction gets declined',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'TRANSACTION_AUTHORIZATION',
      description: 'Alert will be triggered when user makes any transaction',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      }
    },
    {
      id: 'TRANSACTION_REFUND',
      description: 'user will be notified when the transaction refunded',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'STATEMENT_AVAILABLE',
      description: 'user will be notified when the statement is available',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'CARD_ACTIVATE',
      description: 'user will be notified for activate card',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'CARD_FREEZE',
      description: 'user will be notified for card freeze',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'CARD_REPLACE',
      description: 'user will be request for replacement of the card',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'LOST_STOLEN_CONFIRMATION',
      description: 'user will be request for loss and stolen confiramtion',
      delivery: {
        email: {
          default: false,
          enabled: false,
          changeable: false
        },
        text: {
          default: false,
          enabled: false,
          changeable: false
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'CARD_SHIP',
      description: 'user will be request for card shipped',
      delivery: {
        email: {
          default: true,
          enabled: true,
          changeable: false
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'ACCOUNT_STATUS_CHANGED',
      description: 'user will be prompt with account change',
      delivery: {
        email: {
          default: false,
          enabled: false,
          changeable: false
        },
        text: {
          default: false,
          enabled: false,
          changeable: false
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'TRANSACTION_CARD_NOT_PRESENT',
      description: 'user will be prompt with card not found transaction',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'ACCOUNT_CLOSURE',
      description: 'user will be present with close account',
      delivery: {
        email: {
          default: false,
          enabled: false,
          changeable: false
        },
        text: {
          default: false,
          enabled: false,
          changeable: false
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      }
    },
    {
      id: 'PURCHASE_EXCEEDS',
      description:
        'Alert will be triggered when user does transaction with exceeds purchase amount',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      },
      attributes: {
        purchaseExceedsThresholdAmount: {
          type: 'NUMBER',
          description: userSpecifiedAmountValue,
          changeable: true
        }
      }
    },
    {
      id: 'PROMO_PURCHASE_EXPIRY',
      description: 'Alert will be triggered when user promo purchase expiry',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        },
        push: {
          default: false,
          enabled: false,
          changeable: false
        },
        clientNotification: {
          default: false,
          enabled: false,
          changeable: false
        }
      },
      attributes: {
        promoExpiryDays: {
          type: 'NUMBER',
          description: userSpecifiedDayValue,
          changeable: true,
          value: '5'
        }
      }
    },
    {
      id: 'BALANCE_EXCEEDS',
      description:
        'Alert will be triggered when user does transaction with exceeds balance amount.',
      delivery: {
        email: {
          default: false,
          enabled: true,
          changeable: true
        },
        text: {
          default: false,
          enabled: true,
          changeable: true
        }
      },
      attributes: {
        balanceExceedsThresholdAmount: {
          type: 'NUMBER',
          description: 'user specified amount preference value',
          changeable: true
        }
      }
    }
  ]
}
