import { assign, createMachine } from 'xstate'
import { STATES } from 'const/orchestratorMachine'
import { setSelectedAccount } from 'helpers/setSelectedAccount'
import {
  OrchestratorMachineContextSchema,
  OrchestratorMachineEventsSchema
} from './orchestratorMachine.types'

const {
  NONE_MFE_IS_OPEN,
  ACTIVITY_IS_OPEN,
  DETAILS_IS_OPEN,
  MAP_IS_OPEN,
  MOCK_TRIGGERS_IS_OPEN
} = STATES

const setAccountId = assign({
  accountId: (_, event: { accountId: string }) => {
    setSelectedAccount(event.accountId)
    return event.accountId
  }
})

const orchestratorMachine = createMachine({
  id: 'orchestrator',
  predictableActionArguments: true,
  schema: {
    events: {} as OrchestratorMachineEventsSchema,
    context: {} as OrchestratorMachineContextSchema
  },
  context: {
    accountId: ''
  },
  initial: NONE_MFE_IS_OPEN,
  states: {
    NONE_MFE_IS_OPEN: {
      on: {
        OPEN_DETAILS: {
          target: DETAILS_IS_OPEN,
          actions: setAccountId
        },
        OPEN_ACTIVITY: {
          target: ACTIVITY_IS_OPEN,
          actions: setAccountId
        },
        OPEN_MAP: {
          target: MAP_IS_OPEN,
          actions: setAccountId
        },
        OPEN_MOCK_TRIGGERS: MOCK_TRIGGERS_IS_OPEN
      }
    },
    DETAILS_IS_OPEN: {
      on: {
        CLOSE_MFE: NONE_MFE_IS_OPEN
      }
    },
    ACTIVITY_IS_OPEN: {
      on: {
        CLOSE_MFE: NONE_MFE_IS_OPEN
      }
    },
    MAP_IS_OPEN: {
      on: {
        CLOSE_MFE: NONE_MFE_IS_OPEN
      }
    },
    MOCK_TRIGGERS_IS_OPEN: {
      on: {
        CLOSE_MFE: NONE_MFE_IS_OPEN
      }
    }
  }
})

export default orchestratorMachine
