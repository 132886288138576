import {
  iOSBodyScrollBlockerContext,
  iOSBodyScrollBlockerValue
} from 'context/iOSBodyScrollBlockerContext'
import { useContext } from 'react'

/**
 * Get the values from iOSBodyScrollBlockerContext
 * enableBodyScroll, disableBodyScroll
 */
const useiOSBodyScrollBlocker = (): iOSBodyScrollBlockerValue => {
  const iOSContext = useContext(iOSBodyScrollBlockerContext)

  if (iOSContext === undefined) {
    throw new Error(
      'iOSScrollContext used outside of its Provider, make sure you are using this in a child component of the Provider'
    )
  }

  return iOSContext
}

export default useiOSBodyScrollBlocker
