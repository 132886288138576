import { ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ErrorCard from 'ui/molecules/ErrorCard'
import {
  ErrorContainer,
  InnerContainer
} from 'ui/molecules/ErrorComponent/ErrorComponent.styles'
import { useQualtrics, useUser } from 'hooks'
import triggerAnalyticsEvent from 'helpers/triggerAnalyticsEvent'
import { getAppSessionItem } from 'helpers/appSessionItem'
import { authFlow, logoutCorrelationId } from 'const/session'
import { analyticsEvents } from 'helpers/analytics/events'
import {
  CARD_AUTH_FLOW,
  PAYLATER_AUTH_FLOW,
  SECURED_INSTALLMENTS_FLOW
} from 'const/constants'
import ROUTES from 'const/routes'
import prependSlash from 'helpers/prependSlash'
import { Stack } from 'syf-component-library/ui/spacing'
import {
  PAYLATER_DEFAULT_PRODUCT,
  SECURED_INSTALLMENTS_DEFAULT_PRODUCT
} from 'const/analytics'
import {
  DashboardContainer,
  MainWrapper
} from '../AccountsDashboard/AccountsDashboard.styles'

const LogoutCard = (): ReactElement => {
  const navigate = useNavigate()
  const { reloadQualtrics } = useQualtrics()
  const selectedAuthFLow = getAppSessionItem(authFlow)

  const handleSignIn = () => {
    sessionStorage.removeItem(authFlow)

    const returnToSignIn = (route) => navigate(prependSlash(route))

    switch (selectedAuthFLow) {
      case CARD_AUTH_FLOW:
        returnToSignIn(ROUTES.LOGIN_REVOLVING)
        break
      case SECURED_INSTALLMENTS_FLOW:
        returnToSignIn(ROUTES.LOGIN_SECURED_INSTALLMENTS)
        break
      case PAYLATER_AUTH_FLOW:
      default:
        returnToSignIn(ROUTES.LOGIN_LOAN)
    }
  }
  const { currentLanguage } = useUser()

  useEffect(() => {
    const isSecuredInstallmentsFlow =
      selectedAuthFLow === SECURED_INSTALLMENTS_FLOW
    triggerAnalyticsEvent(
      analyticsEvents.logoutLoad(
        getAppSessionItem(logoutCorrelationId),
        isSecuredInstallmentsFlow
          ? SECURED_INSTALLMENTS_DEFAULT_PRODUCT
          : PAYLATER_DEFAULT_PRODUCT
      )
    )
    reloadQualtrics()
    sessionStorage.removeItem(logoutCorrelationId)
  }, [])

  useEffect(() => {
    reloadQualtrics()
  }, [currentLanguage])

  return (
    <div>
      <MainWrapper>
        <DashboardContainer>
          <ErrorContainer>
            <InnerContainer aria-label="logout page">
              <Stack size="large" respondToBreakpoints={['small']} />
              <ErrorCard
                image="logout"
                altText="logoutImgAlt"
                bodyTitle="signOutSuccessful"
                bodyContent="seeYouLater"
                centerButtonClick={handleSignIn}
                centerButtonText="backToSignIn"
                currentLanguage={currentLanguage}
              />
            </InnerContainer>
          </ErrorContainer>
        </DashboardContainer>
      </MainWrapper>
    </div>
  )
}

export default LogoutCard
