/**
 * Gets an array of elements and a new length for it
 * @param originalArray the original array of elements
 * @param newLength the new length for the array of elements
 * @returns an array based on the original array, if the length is 0 it should be empty,
 * if the length is lesser is should be sliced, if the length is greater it should repeat the elements until the new length is reached
 */
const resizeArray = <T>(originalArray: T[], newLength: number): T[] => {
  const originalLength = originalArray.length

  // if the new length is 0 return an empty array
  if (newLength <= 0) {
    return []
  }

  if (newLength <= originalLength) {
    // if the new length is less than or equal to the original length, slice the array
    return originalArray.slice(0, newLength)
  }

  // if the new length is greater, repeat elements until the new length is reached
  const repeatedArray: T[] = []
  for (let i = 0; i < newLength; i++) {
    const index = i % originalLength
    repeatedArray.push(originalArray[index])
  }

  return repeatedArray
}

export default resizeArray
