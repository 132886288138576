import { getQueryParam } from 'syf-js-utilities'
import redirectToUrl from 'syf-js-utilities/helpers/redirectToUrl'
import {
  SYF_FIND_ACCOUNT_URL,
  UNIVERSAL_ACTIVATION_FLOW
} from 'const/constants'
import { QUERY_PARAMS } from 'const/queryParams'
import { getAppSessionItem, setAppSessionItem } from 'helpers/appSessionItem'
import type { QueryParamsCheckProps } from './QueryParamsCheck.types'

/**
 * Component to check if the required query params are present in the url
 * for a certain authFlow
 *
 * @param {queryParamsProps} props - Component props containing:
 * @param {ReactElement} props.children - what to render if all the required query params are present
 * @param {AuthFlowType} props.authFlow - the specific type of authFlow page and flow user will be directed to
 * @returns {ReactElement} - This element children or redirects the user to another page
 */
const QueryParamsCheck = ({ children, authFlow }: QueryParamsCheckProps) => {
  if (authFlow === UNIVERSAL_ACTIVATION_FLOW) {
    const client =
      getQueryParam(QUERY_PARAMS.client) ??
      getAppSessionItem(QUERY_PARAMS.client)
    if (!client) {
      redirectToUrl(SYF_FIND_ACCOUNT_URL)
      return
    }
    setAppSessionItem(QUERY_PARAMS.client, client)
  }
  return children
}

export default QueryParamsCheck
