import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import { fontSizes, spacing } from 'syf-component-library/defaultTheme'
import { Button } from 'syf-component-library/ui/atoms'

export const NavContainer = styled.div`
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${query.greaterThanLarge} {
    height: 80px;
  }

  @media ${query.lessThanMedium} {
    padding-top: ${spacing.small}px;
    display: inline-block;
    min-height: 56px;
  }
`

export const ContentContainer = styled.header`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  max-width: 1200px;
  padding: ${spacing.xlarge}px 20px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  /* center the max-width container on large screens */
  margin: auto;
  @media ${query.lessThanMedium} {
    padding: 20px 10px;
  }
`

export const Logo = styled.img`
  grid-column: 2;
  height: 40px;
  width: 224px;
  @media ${query.lessThanSmall} {
    height: 30px;
    width: 140px;
  }
`

export const LanguageButton = styled(Button)`
  padding: ${spacing.medium}px ${spacing.small}px;
  justify-self: end;
  display: flex;
  gap: ${spacing.small}px;
  color: white;
  font-size: ${fontSizes.large}px;
  // so bottom of icon doesn't get cut off
  overflow: unset;

  @media ${query.greaterThanMedium} {
    &:focus {
      outline: ${({ theme }) => theme.focus} auto 5px;
    }
  }
`

export const LanguageButtonText = styled.span`
  // should be 7/8 size of 16px aka 14px
  font-size: ${fontSizes.root * 0.875}px;
`

export const IconWrapper = styled.span`
  margin-right: 2px;
`
