import styled from 'styled-components'
import { spacing } from 'syf-component-library/defaultTheme'
import query from 'syf-component-library/const/mediaQueries'
import Grid from 'syf-component-library/ui/patterns/Grid'

export const AccountCardsGrid = styled(Grid)`
  /** values from mediaQueries library: (max-width: ${768 - 1}px) */
  @media ${query.lessThanMedium} {
    display: grid;
    justify-content: unset;
    flex-direction: column;
    margin-left: ${spacing.base}px;
  }
`

export const AccountsContainer = styled.div`
  display: grid;
  gap: ${spacing.xlarge}px;

  &.vertical {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${query.lessThanMedium} {
    &.vertical {
      display: flex;
      gap: ${spacing.base}px;
      flex-direction: column;
    }
  }
  /**  values: (min-width: 768px) and (max-width: ${1024 - 1}px) 
    * Adding this so that card's info can be readable instead of shrinking
  */
  @media ${query.betweenMediumLarge} {
    &.vertical {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`
