import styled from 'styled-components'
import {
  fontSizes,
  lineHeights,
  spacing
} from 'syf-component-library/defaultTheme'
import { DrawerHeader } from 'syf-component-library/ui/atoms'
import query from 'syf-component-library/const/mediaQueries'
import { Small } from 'syf-component-library/ui/typography'

export const AccountHeader = styled(DrawerHeader)`
  padding: ${spacing.small}px ${spacing.base}px;
  gap: ${spacing.xsmall}px;
  border-bottom: 2px solid #e9e9e9;

  @media ${query.greaterThanSmall} {
    padding: ${spacing.medium}px ${spacing.large}px;
    gap: ${spacing.base}px;
  }
`
export const AccountSubtitle = styled(Small)`
  @media ${query.greaterThanSmall} {
    // Body styles
    font-size: ${fontSizes.root}px;
    line-height: ${lineHeights.root};
  }
`
