import { useState } from 'react'
import { generateUUID } from 'syf-js-utilities'
import useOrchestratorHelpers from 'orchestrator/OrchestratorHelpers'

interface ResetMFEErrorKey {
  resetKey: string
  handleReset: () => void
}

/**
 * useResetMFEErrorKey helps mfe error boundary to reset and rerender when it is remounted by assigning a new key
 * @param mfeName
 * @returns resetKey key to be used in the MFEErrorBoundary
 * @returns handleReset() function to be used in the MFEErrorBoundary
 */
const useResetMFEErrorKey = (): ResetMFEErrorKey => {
  const [resetKey, setResetKey] = useState(`${generateUUID()}`)
  const { closeMFE } = useOrchestratorHelpers()

  const handleReset = (): void => {
    setResetKey(`${generateUUID()}`)
    closeMFE()
  }
  return { resetKey, handleReset }
}

export default useResetMFEErrorKey
