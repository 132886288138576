import { ReactElement, Suspense, lazy } from 'react'
import { useMFEConfigs } from 'hooks'
import useResetMFEErrorKey from 'hooks/useResetMFEErrorKey'
import MFEErrorBoundary from 'ui/molecules/MFEErrorBoundary/MFEErrorBoundary'
import { refreshChunk } from 'helpers/utils/utils'

// defer load & code split this to a separate chunk for initial load perf
const AccountDetails = lazy(() =>
  refreshChunk(
    () =>
      import(
        /* webpackChunkName: "mfe-acct-details" */
        /* webpackPrefetch: true */
        'account-details-mfe'
      ),
    'account-details-mfe'
  )
)

const AccountDetailsAdapter = (): ReactElement => {
  const { getAccountDetailsConfig } = useMFEConfigs()
  const { resetKey, handleReset } = useResetMFEErrorKey()

  return (
    <MFEErrorBoundary key={resetKey} handleReset={handleReset}>
      <Suspense fallback={<></>}>
        <AccountDetails {...getAccountDetailsConfig()} />
      </Suspense>
    </MFEErrorBoundary>
  )
}

export default AccountDetailsAdapter
