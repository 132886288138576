const START_OF_STRING = 0
const NEW_RELIC_CHARACTER_LIMIT = 254
/**
 * Formats and compresses a string to the specified character limit for New Relic logs.
 * Removes extra whitespace and new lines then cuts characters.
 * // https://docs.newrelic.com/docs/data-apis/custom-data/custom-events/data-requirements-limits-custom-event-data/
 *
 * @param {string} value - The input string to be truncated.
 * @returns {string} The truncated string, limited to the New Relic character limit.
 */
const compressToNewRelicCharacterLimit = (value: string) => {
  // regex to remove any spaces, new lines, tabs, etc
  const noBlankSpaceRegex = /[\t\r\n\\ ]/g
  const cleanedValue = value.replaceAll(noBlankSpaceRegex, '')

  return cleanedValue.slice(START_OF_STRING, NEW_RELIC_CHARACTER_LIMIT)
}

export default compressToNewRelicCharacterLimit
