import queryClient from 'const/queryClient'
import {
  CacheQueriesDataType,
  CacheQueriesType,
  ReturnGetQueryDataFromCache
} from './cacheQueries.types'

/**
 * This object corresponds to the identifier of queries from React-Query cache
 */
interface QueryCacheQueries {
  summary: (
    accountId: string
  ) => (string | { accountId: string; accountIdType: string })[]
}

const cacheQueries: QueryCacheQueries = {
  summary: (accountId: string) => [
    'v2/credit/accounts/summary',
    {
      accountId,
      accountIdType: 'guid'
    }
  ]
}

/** getQueryDataFromCache provides data from cache for specific queries */
const getQueryDataFromCache = (
  type: CacheQueriesType,
  data: CacheQueriesDataType
): ReturnGetQueryDataFromCache => {
  /** To be changed for a switch when we have more queries to use */
  if (type === CacheQueriesType.SUMMARY) {
    return queryClient?.getQueryData<ReturnGetQueryDataFromCache>(
      cacheQueries.summary(data.accountId)
    )
  }
}

export { cacheQueries, getQueryDataFromCache }
