import { SUPPORTED_LANGUAGES } from 'strings/lang/lang.constants'
import { SupportedLanguage } from 'syf-js-utilities'

/**
 *  Theres only two available languages (EN and ES),
 *  only EN needs the country code attached at
 *  the end (US)
 */

const getLanguageWithCountryCode = (lang: SupportedLanguage) =>
  lang === SUPPORTED_LANGUAGES.EN ? `${lang}-us` : lang

export default getLanguageWithCountryCode
