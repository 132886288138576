import { setupWorker } from 'msw'
import config, { PUBLIC_URL } from 'const/config'
import handlers, { isBrowserEnvironment } from './handlers'

declare global {
  interface Window {
    mswStarted: boolean
  }
}

// only allow msw browser server to start if in a browser environment otherwise it will cause issues
const mswBrowser = isBrowserEnvironment ? setupWorker(...handlers) : null

export const startMSW = async (): Promise<void> => {
  /**
   * Service worker requires trailing slash
   * https://github.com/mswjs/msw/issues/484
   */
  if (window.location.pathname === PUBLIC_URL) {
    window.location.pathname += '/'
  }
  await mswBrowser?.start({
    serviceWorker: {
      url: `/${config.BASENAME}/mockServiceWorker.js`
    }
  })
  window.mswStarted = true
}

export const stopMSW = (): void => {
  mswBrowser?.stop()
  window.mswStarted = false
}

export default mswBrowser
