import revokeAccessToken from 'authorization-module/helpers/revokeAccessToken'
import config from '../const/config'
import { getSessionAccessToken } from './authUtils'

/**
 * Revokes user's current auth credential token used for syf api calls attached
 * on the authorization header by notifying the auth server to invalidate the
 * passed access token. Won't make the fetch if no access token is available.
 * @returns {Promise<void>} a promise that doesn't return anything
 */
const revokeAccessTokenOnServer = async (): Promise<void> => {
  const accessToken = getSessionAccessToken()
  const { API_HOST, APIGEE_CLIENT_ID } = config
  // can't revoke anything if we don't have an access token
  if (!accessToken) return
  /*
   * calls the /revoke api to cancel the token on the auth server so it can't be used
   * note that /revoke always returns success status for security reasons
   */
  return revokeAccessToken(API_HOST, APIGEE_CLIENT_ID, accessToken)
}

export default revokeAccessTokenOnServer
