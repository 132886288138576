import { HTTPDataError } from 'services/helpers/HTTPDataError'
import type HTTPError from 'syf-js-utilities/typings/HTTPError'
import getFirstBusinessErrorInResponse from './getFirstBusinessErrorInResponse'
import isBusinessErrorData from './isBusinessErrorData'

// to avoid typing this out many times
type ErrorTypes = Error | HTTPError | HTTPDataError

// for type narrowing
const isError = (e: ErrorTypes): e is Error => e.name === 'Error'
const isHTTPError = (e: ErrorTypes): e is HTTPDataError =>
  e.name === 'HTTPError'
const isHTTPDataError = (e: ErrorTypes): e is HTTPDataError =>
  e.name === 'HTTPDataError'

/**
 * Gets the most specific type of error it can find from the Error obj's data.
 * Pass in an Error like object, this function will get the most specific data it can
 * for handling the error.
 * @param error {object} an Error like object to extract data from
 * @returns {string} a string of error info that can be switched on
 */
const getErrorCaseFromError = (error: ErrorTypes): string => {
  // httpdata api errors may have more specific business error codes that need specifc handling
  if (isHTTPDataError(error)) {
    const { data, response } = error
    const isBusinessError = isBusinessErrorData(data)

    // return the more specific business error code if it exists
    return isBusinessError
      ? getFirstBusinessErrorInResponse(data).code
      : `${response.status}`
  }

  if (isHTTPError(error)) {
    // for generic api errors, just handle based on the http status code
    return `${error.response.status}`
  }

  /*
   * this type guard has to be last, otherwise ts thinks the error will never narrow
   * to a more specific type and the other type guard clauses think the error obj is 'never'
   * which makes accessing any properties on the error obj not type check
   */
  if (isError(error)) {
    // for general errors we just check the message passed
    return error.message
  }

  // if none of the expected error types, just coerce what we can to string
  return `${error}`
}

export default getErrorCaseFromError
