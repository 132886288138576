/* eslint-disable sonarjs/no-duplicate-string */
import { assign, createMachine } from 'xstate'
import arrayAnyTrue from 'helpers/arrayAnyTrue'
import { STATES } from 'const/accountCardMachine'
import {
  AccountCardMachineContextSchema,
  AccountCardMachineEventsSchema
} from './accountCardMachine.types'

const { READY, LOADING } = STATES
const handleLoadingState = assign({
  accountCardLoading: (
    context: AccountCardMachineContextSchema,
    event: AccountCardMachineEventsSchema
  ) => {
    const newState = { ...context }
    if (newState.accountCardLoading === undefined) {
      newState.accountCardLoading = {}
    }
    const { accountId, isLoading } = event.accountCardLoading
    newState.accountCardLoading[accountId] = isLoading
    return newState.accountCardLoading
  }
})

const hasLoaded = ({ accountCardLoading }) => {
  const isLoadingArray: boolean[] = []
  Object.keys(accountCardLoading).forEach((accountCard) => {
    const isLoading = accountCardLoading[accountCard]
    isLoadingArray.push(isLoading)
  })
  return !arrayAnyTrue(isLoadingArray)
}

const accountCardMachine = createMachine({
  id: 'accountCard',
  predictableActionArguments: true,
  schema: {
    events: {} as AccountCardMachineEventsSchema,
    context: {} as AccountCardMachineContextSchema
  },
  context: {
    accountCardLoading: {}
  },
  initial: READY,
  states: {
    READY: {
      on: {
        CHANGE_CARD_LOADING_STATE: {
          target: LOADING,
          actions: handleLoadingState
        }
      }
    },
    LOADING: {
      always: [
        {
          target: READY,
          cond: hasLoaded
        }
      ],
      on: {
        CHANGE_CARD_LOADING_STATE: {
          target: LOADING,
          actions: handleLoadingState
        }
      }
    }
  }
})

export default accountCardMachine
