import makeSafeURL from 'helpers/makeSafeURL'

interface AbsoluteRedirectProps {
  /** Full URL string to redirect user to ie https://thing.com/page */
  to: URL['href']
  /**
   * Whether to pass through the query string params for the current page to the redirected one
   * ie all the values after the ? in the url
   */
  passQueryParams?: boolean
}
/**
 * Redirect component similar to react router's Navigate, except this allows
 * an absolute full url ie https://something.com/page
 * Also can pass through query string params, in case we're going to the same page on a different host.
 * @param props @see {@link AbsoluteRedirectProps}
 * @returns null
 */
const AbsoluteRedirect = ({
  to,
  passQueryParams = true
}: AbsoluteRedirectProps): null => {
  // escaping the url query string and main url pieces to prevent XSS attacks
  const urlSearchParams = new URLSearchParams(window.location.search)
  const safeURLQueryString =
    passQueryParams && window.location.search
      ? `?${urlSearchParams.toString()}`
      : ''
  const safeURI = makeSafeURL(`${to}${safeURLQueryString}`)

  window.location.href = safeURI

  return null
}

export default AbsoluteRedirect
