export const setBodyScrollBlockStyles = (): void => {
  const { scrollY } = window
  // Need to set position as fixed because overflow: hidden does not work correctly in nested divs for certain iOS devices
  document.body.style.position = 'fixed'
  document.body.style.width = '100vw'
  document.body.style.top = `${-scrollY}px`
}

export const setBodyScrollUnblockStyles = (
  position: string,
  top: string
): void => {
  document.body.style.position = position
  document.body.style.top = top
  document.body.style.width = ''
}
