import config from 'const/config'
import { authFlow } from 'const/session'
import { UNIVERSAL_ACTIVATION_FLOW } from 'const/constants'
import { getAppSessionItem } from 'helpers/appSessionItem'

/**
 * This returns the corresponding signin page route that the global auth server looks
 * for in our apigee developer app loginUrlPattern attribute. In Peaks, we get
 * the matching value by looking at the current authFlow.
 * This is the page the user gets redirected to if the global auth server sees an
 * unauthenticated user.
 * @returns string - the signin page route
 */
export const getTargetOrigin = () => {
  const { TARGET_ORIGIN, TARGET_ORIGIN_ACTIVATION } = config
  const sessionAuthFlow = getAppSessionItem(authFlow)
  // only 1 check because only activation has a different signin url
  const isUniversalActivation = sessionAuthFlow === UNIVERSAL_ACTIVATION_FLOW

  return isUniversalActivation ? TARGET_ORIGIN_ACTIVATION : TARGET_ORIGIN
}

export default getTargetOrigin
