import { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { PageNotFound } from 'ui/pages'
import { isMobileSafari } from 'react-device-detect'
import {
  IOS_SAFARI_MODULE_IMPORT_ERROR,
  MODULE_IMPORT_ERROR
} from 'const/moduleErrors'

const RoutesErrorElement: React.FC = () => {
  const error = useRouteError()

  /*
   * only on mobile safari it seems the window location redirect from the startAuthentication fn
   * interrupts module script loading causing an error screen to show while the redirect is still loading
   */
  const handleMobileSafariModuleImportBug = (_error) => {
    const errorMessage = _error.toString()
    if (isMobileSafari && errorMessage === MODULE_IMPORT_ERROR) {
      // rethrowing with a new message so error component only targets this 1 specific case
      throw new Error(IOS_SAFARI_MODULE_IMPORT_ERROR)
    }
  }

  if (isRouteErrorResponse(error)) {
    // User hit a url we are not able to handle
    return <PageNotFound />
  }
  handleMobileSafariModuleImportBug(error)
  /** Error Boundary will take care of showing the appropriate image/message for the error occurred */
  throw error
}

export default RoutesErrorElement
