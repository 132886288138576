import { authFlow } from 'const/session'
import { getAppSessionItem } from 'helpers/appSessionItem'

type AuthScopeConfig = readonly string[]
interface ScopesConfig {
  paylater: AuthScopeConfig
  securedinstallments: AuthScopeConfig
  card: AuthScopeConfig
  activate: AuthScopeConfig
}
/**
 * This function returns the correct scopes for different auth flows.
 * We need this to control which scopes are allowed from each auth flow.
 * For example, we don't need Alerts in secured installements, so we don't
 * need to include profile scopes.
 * @returns string
 */
export const getAuthScopes = () => {
  const BASE_AUTH_SCOPES: AuthScopeConfig = [
    'accountinfo:read',
    'accounttransaction:read',
    'repayments.autopay.status.get',
    'repayments.autopay.update',
    'repayments.payment-method.get',
    'repayments.payment-method.add',
    'repayments.payment.make',
    'repayments.payment.get',
    'repayments.eligibility.get'
  ]

  const PAYLATER_AUTH_SCOPES = [
    'profile:read',
    'profile:read:sensitive',
    'alert-preferences:read',
    'alert-preferences:update',
    'alert-preferences:register'
  ]

  const ACTIVATION_AUTH_SCOPES = ['cardmanagement.activation:write:sensitive']

  const scopes: ScopesConfig = {
    paylater: [...BASE_AUTH_SCOPES, ...PAYLATER_AUTH_SCOPES],
    securedinstallments: BASE_AUTH_SCOPES,
    card: BASE_AUTH_SCOPES,
    activate: ACTIVATION_AUTH_SCOPES
  }
  const sessionAuthFlow = getAppSessionItem(authFlow)

  return scopes[sessionAuthFlow].join(' ')
}

export default getAuthScopes
