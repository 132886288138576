import { ProductType } from 'syf-typings'

export const PAYLATER_MONTHLY_PHONE_NUMBER = '1-844-373-4960'
export const PAYLATER_PAY_IN_4_PHONE_NUMBER = '1-855-945-3576'
export const SECURED_INSTALLMENTS_PHONE_NUMBER = '1-866-220-9432'
export const INSTALLMENT_LOAN_PAY_IN_FOUR: ProductType =
  'INSTALLMENT_LOAN_PAY_IN_FOUR'
export const SECURED_INSTALLMENTS_PRODUCT_TYPE: ProductType =
  'INSTALLMENT_LOAN_SECURED'

const getMAPHelpPhoneNumber = (productType: ProductType): string => {
  switch (productType) {
    case INSTALLMENT_LOAN_PAY_IN_FOUR:
      return PAYLATER_PAY_IN_4_PHONE_NUMBER
    case SECURED_INSTALLMENTS_PRODUCT_TYPE:
      return SECURED_INSTALLMENTS_PHONE_NUMBER
    default:
      return PAYLATER_MONTHLY_PHONE_NUMBER
  }
}

export default getMAPHelpPhoneNumber
