import styled from 'styled-components'
import query from 'syf-component-library/const/mediaQueries'
import PageContainer from 'ui/pages/Pages.subcomponents'
import { spacing } from 'syf-component-library/defaultTheme'

export const Container = styled(PageContainer)`
  margin: ${spacing.small}px auto;

  @media ${query.greaterThanMedium} {
    max-width: 1200px;
  }

  @media ${query.lessThanMedium} {
    padding: 0 ${spacing.small}px;
  }

  * {
    box-sizing: border-box;
  }
`

export const InnerContainer = styled.div`
  text-align: center;
  width: 100%;
  position: relative;

  @media ${query.greaterThanMedium} {
    width: 768px;
  }
`

export const PageNotFoundHeader = styled.div`
  background-color: ${({ theme }) => theme.syfYellow};
  height: 121px;
  width: 100%;

  @media ${query.greaterThanMedium} {
    height: 232px;
  }
`

export const CardImage = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  overflow: hidden;
  height: 131px;
  top: 3px;

  @media ${query.greaterThanMedium} {
    height: 250px;
    top: 6px;
  }
`
