import styled from 'styled-components'
import Button from 'syf-component-library/ui/atoms/Button'
import query from 'syf-component-library/const/mediaQueries'
import { colors, spacing } from 'syf-component-library/defaultTheme'

export const IconWrapper = styled.span`
  margin-right: 5px;
`

export const DropdownRow = styled(Button)`
  width: 100%;
  padding: 0 20px;
  margin: 0;
  height: 40px;
  justify-content: flex-start;

  &:hover {
    text-decoration: none;
  }

  @media ${query.greaterThanMedium} {
    width: 160px;
  }
`

export const NavigationContainer = styled.header`
  margin-bottom: ${spacing.xxlarge + spacing.medium}px;
  width: 100%;

  @media ${query.lessThanMedium} {
    margin-bottom: ${spacing.xxlarge}px;
  }
  /** removing a line that is showing at the right of the language button when user is not logged in */
  & #language-button + span {
    display: none;
  }

  /** Adding left border to alerts icon */
  & #alerts-button {
    border-left: 1px solid ${colors.altoGrey};
    // temporary fix to align the icon in the middle, to be fixed in the component itself
    & > span {
      margin-left: 5px;
    }
  }
`
