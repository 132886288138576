import styled from 'styled-components'
import {
  colors,
  fontSizes,
  opacities,
  spacing
} from 'syf-component-library/defaultTheme'
import { Inset } from 'syf-component-library/ui/spacing'
import FullWidthButton from 'ui/atoms/FullWidthButton'

export const CollapseWrapper = styled.div`
  margin-bottom: ${spacing.base}px;
`

export const CollapseHeader = styled(FullWidthButton)`
  justify-content: space-between;
  gap: ${spacing.small}px;
  text-transform: capitalize;
  font-size: ${fontSizes.medium}px;
  // making a button look like a card
  box-shadow: 0 1px 10px 0 ${opacities.rgbaLight10},
    0 4px 5px 0 ${opacities.rgbaLight08}, 0 2px 4px 0 ${opacities.rgbaLight04};
  border: none;
`

export const CollapseContainer = styled(Inset)`
  background-color: ${colors.lightestGrey};
`
