import { useQuery } from '@tanstack/react-query'
import type { ConfigType } from 'const/config'
import defaultConfig, { PROD_GPSHOPPER_ASSETS_URL } from 'const/config'
import fetchBasic from 'services/helpers/fetchBasic'
import { getAppSessionItem } from 'helpers/appSessionItem'
import { useProdFlags } from 'const/session'
import {
  FlagsQueryData,
  FlagsQueryProps,
  FlagsQueryResult
} from './flagsQuery.types'

const getFlagsAssetPaths = (config: ConfigType) => {
  const { ASSETS_HOST, APP_REGION } = config
  const flagsPath = 'peaks/flags.json'
  const uatFlagsPath = 'peaks/flags-uat.json'
  // in case we want to test the prod flags in a nonprod environment
  const shouldUseProdFlags = getAppSessionItem(useProdFlags) === 'true'
  const isUAT = APP_REGION === 'uat'

  if (isUAT) {
    return {
      host: ASSETS_HOST,
      // uat is the only env with a different flags.json file path
      path: shouldUseProdFlags ? flagsPath : uatFlagsPath
    }
  }

  return {
    host: shouldUseProdFlags ? PROD_GPSHOPPER_ASSETS_URL : ASSETS_HOST,
    path: flagsPath
  }
}

const defaultOptions = { config: defaultConfig }
/**
 * Grabs the feature flags json file from the static assets server.
 * @param props @see {@link FlagsQueryProps} - options for the flags query
 * @returns the same as useQuery returns, an obj with .data and other query stuff
 */
const useFlagsQuery = ({
  config = defaultConfig,
  queryOptions = {}
}: FlagsQueryProps = defaultOptions): FlagsQueryResult => {
  const { host, path } = getFlagsAssetPaths(config)

  const useQueryOptions = {
    queryKey: [path],
    queryFn: async () => {
      // to ensure we get a fresh copy from the server
      const cacheBusterParam = `?nocache=${Date.now()}`
      const uncachedFlagsURL = `${path}${cacheBusterParam}`

      const fetchOptions = {
        baseURL: host
      }

      // fetchBasic because it's just getting a static asset
      return fetchBasic<FlagsQueryData>(uncachedFlagsURL, fetchOptions)
    },
    ...queryOptions
  }

  return useQuery(useQueryOptions)
}

export default useFlagsQuery
