import getCountryLanguage from 'helpers/getCountryLanguage'
import type { AuthFlowType } from 'typings/Auth'
import { ACTIVATION_ANALYTICS_PROPS } from 'const/analytics'
import { getAnalyticsObjectForError } from './helpers'
import type { AnalyticsEvent } from './analytics.types'

const dashboardLoad = (
  accountIds: string[],
  clientName: string,
  productType: string,
  pageKind: string
): AnalyticsEvent => ({
  Country_Language: getCountryLanguage(),
  pageName: 'view',
  ClientName: clientName || 'generic',
  PageFunction: 'dashboard',
  Product: productType || 'generic',
  numberofaccounts: `${accountIds.length}`,
  PageKind: pageKind
})

const mfeCloseAndBackToDashboard = (accountIds: string[]): AnalyticsEvent => ({
  pageName: 'view',
  PageFunction: 'dashboard',
  numberofaccounts: `${accountIds.length}`
})

const error = (errorMsg: string, authFlow: AuthFlowType): AnalyticsEvent => {
  return getAnalyticsObjectForError(errorMsg, authFlow)
}

/**
 * signInError is for updating analytics just on sign in pages
 * when a user logs in with the wrong credentials. This will update
 * ErrorMessage, messageType and pageSubFunction if needed in the _SFDDL object
 * for analytics.
 * @param messageType
 * @param errorMessage
 * @param pageSubFunction
 */
const signInError = (
  messageType: string,
  errorMessage: string,
  pageSubFunction: string
): AnalyticsEvent => ({
  ClientName: 'generic',
  PageKind: 'login',
  pageName: 'error',
  ErrorMessage: errorMessage,
  messageType,
  PageSubFunction: pageSubFunction
})

const logoutClick = (): AnalyticsEvent => ({
  PageFunction: 'logout',
  pageName: 'complete',
  PageKind: 'logout'
})

const loginLoad = (
  correlationId: string,
  pageSubFunction: string
): AnalyticsEvent => ({
  Country_Language: getCountryLanguage(),
  ClientName: 'generic',
  PageKind: 'login',
  pageName: 'start',
  Token: correlationId,
  PageSubFunction: pageSubFunction
})

const logoutLoad = (
  correlationId: string,
  Product: string
): AnalyticsEvent => ({
  Country_Language: getCountryLanguage(),
  ClientName: 'generic',
  CustomerType: 'consumer',
  Product,
  ErrorMessage: '',
  PageKind: 'logout',
  PageFunction: 'logout',
  PageSubFunction: '',
  pageName: 'complete',
  MessageType: '',
  PNL: 'peaks',
  Token: correlationId
})

const activationSignIn = (
  correlationId: string,
  client: string
): AnalyticsEvent => ({
  Country_Language: getCountryLanguage(),
  ClientName: client,
  CustomerType: 'consumer',
  Product: 'generic',
  ErrorMessage: '',
  PageKind: ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_KIND,
  PageFunction: ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_FUNCTION,
  PageSubFunction: '',
  pageName: 'start',
  MessageType: '',
  PNL: 'peaks',
  Token: correlationId
})

const activationSignInError = (
  client: string,
  messageType: string,
  errorMessage: string,
  message: string
): AnalyticsEvent => ({
  ClientName: client,
  ErrorMessage: errorMessage,
  MessageType: messageType,
  Message: message,
  pageName: 'error',
  PageFunction: ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_FUNCTION,
  PageKind: ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_KIND
})

const activationIncompleteForm = (
  correlationId: string,
  client: string
): AnalyticsEvent => ({
  Country_Language: getCountryLanguage(),
  ClientName: client,
  CustomerType: 'consumer',
  Product: 'generic',
  ErrorMessage: '',
  PageKind: ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_KIND,
  PageFunction: ACTIVATION_ANALYTICS_PROPS.ACTIVATION_PAGE_FUNCTION,
  PageSubFunction: '',
  pageName: 'error',
  MessageType: 'field error',
  Message: 'fields incomplete',
  PNL: 'peaks',
  Token: correlationId
})

export const analyticsEvents = {
  activationSignIn,
  activationIncompleteForm,
  mfeCloseAndBackToDashboard,
  dashboardLoad,
  error,
  loginLoad,
  logoutLoad,
  logoutClick,
  signInError,
  activationSignInError
}
