import { generateUUID } from 'syf-js-utilities'
import generateTransId from 'syf-js-utilities/helpers/generateTransId'
/**
 * Generates cryptographically strong v4 UUID--random hexadecimal values in a 36 character long string.
 * https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
 * For use as a transaction id in services.
 * @returns str - 36 character long random string, hyphenated
 */
const getTransId = (): string => {
  /*
   * fallback needed for specific old ios versions (14.5, 14.15.1) that don't support crypto.randomUUID
   * directly call randomUUID method here because reassigning to another variable changes the value of this, causing an illegal invocation error
   */
  return generateTransId() || generateUUID()
}

export default getTransId
