/**
 * Helper for the main helper function.
 * Converts a Headers instance into an object with string keys and values.
 *
 * @param {Headers} headersInstance - The Headers instance to be converted.
 * @returns {Record<string, string>} An object with the same key-value pairs as the input Headers instance, but with all values converted to strings.
 */
const getNewStringObjectFromHeaders = (
  headersInstance: Headers
): Record<string, string> => {
  const newHeadersObject = {}
  const setStringObjectFromHeaders = (
    currentValue: string,
    currentKey: string
  ) => {
    newHeadersObject[currentKey] = `${currentValue}`
  }
  headersInstance.forEach(setStringObjectFromHeaders)
  return newHeadersObject
}

/**
 * Converts an array of key-value arrays  into a string-valued object.
 *
 * @param {Record<string, string>} newHeaders - The object to which the key-value pair will be added.
 * @param {[string, string]} current - An array containing the key-value pair to be added to the object.
 * @returns {Record<string, string>} The updated object with the new key-value pairs added.
 */
const toStringObjectFromArray = (
  newHeaders: Record<string, string>,
  current: [string, string]
): Record<string, string> => {
  const KEY_INDEX = 0
  const VALUE_INDEX = 1
  const key = current[KEY_INDEX]
  const value = current[VALUE_INDEX]

  // disable bc known issue with this rule in reduce() to create an object
  // eslint-disable-next-line no-param-reassign
  newHeaders[key] = value ? `${value}` : ''
  return newHeaders
}

/**
 * Converts a headers object into a compatible format to pass to
 * new relic logger functions ie a plain object with string values
 * or anything json compatible.
 *
 * @param {HeadersInit} headers - some form of headers object: array of key value arrays, object, or Headers instance
 * @returns {Object} object - An object with the same keys as the input object, but with all values converted to strings.
 */
const makeNewRelicFormattedHeaders = (headers: HeadersInit) => {
  const isHeadersObject = headers instanceof Headers
  const isArray = Array.isArray(headers)

  if (isHeadersObject) {
    return getNewStringObjectFromHeaders(headers)
  }
  if (isArray) {
    return headers.reduce(toStringObjectFromArray, {})
  }

  return headers
}

export default makeNewRelicFormattedHeaders
