import { useEffect } from 'react'
import config from 'const/config'
import useFlagsQuery from 'services/flagsQuery/flagsQuery'
import {
  featureFlagsEventName,
  featureFlagsFetchErrorKey,
  featureFlagsKey
} from 'const/flags/globals'
import { setFeatureFlagsInStore } from 'helpers/featureFlags'

/**
 * Checks if the initial feature flag json fetch ran into an error
 * by looking for a fetch error in local storage or if cached flags are missing in local storage.
 * If so, trigger a a refetch to grab the feature flags json and set the result into local storage.
 * @returns void
 */
const useUpdateFeatureFlags = (): void => {
  // only neec to refetch if the initial fetch ran into an error
  const isInitialFlagFetchError =
    localStorage.getItem(featureFlagsFetchErrorKey) === 'true'
  const isFeatureFlagsStored = localStorage.getItem(featureFlagsKey)

  const { data, isSuccess } = useFlagsQuery({
    config,
    queryOptions: {
      enabled: isInitialFlagFetchError || !isFeatureFlagsStored,
      // only need to fetch it once
      staleTime: Infinity
    }
  })

  useEffect(() => {
    if (isSuccess && data) {
      // cache the feature flags values in local storage for fast future checks
      setFeatureFlagsInStore(data)
      // notifies app listeners to do a live update of their cached feature flag data
      window.dispatchEvent(new Event(featureFlagsEventName))
      // so we don't attempt another flag refetch after this one succeeded
      localStorage.removeItem(featureFlagsFetchErrorKey)
    }
  }, [isSuccess])
}

export default useUpdateFeatureFlags
