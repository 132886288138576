/**
 * Adds a New Relic custom PageAction event with the given name and attributes to be
 * sent in the next event harvesting cycle (every 30 secs).
 * https://docs.newrelic.com/docs/data-apis/custom-data/custom-events/report-browser-monitoring-custom-events-attributes/
 *
 * @param {string} actionName - The name of the page action.
 * @param {Object} [attributes={}] - Additional attributes to include with the page action.
 */
const setNewRelicPageAction = (
  actionName: string,
  attributes: Record<string, newrelic.SimpleType> = {}
): string => {
  if (!window.newrelic) {
    return ''
  }

  try {
    newrelic.addPageAction(actionName, attributes)
    return JSON.stringify({
      actionName,
      attributes
    })
  } catch (error) {
    console.warn('Error setting New Relic page action:', error)
    return ''
  }
}

export default setNewRelicPageAction
