import { LANGUAGE_KEY } from 'strings/lang/lang.constants'
import { type SupportedLanguage } from 'syf-js-utilities'
/**
 * Sets the value of the currently selected user language in local storage.
 * @return {string} the language value to set, ie 'en'
 */
const setLanguageInLocalStorage = (
  language: SupportedLanguage
): SupportedLanguage => {
  localStorage.setItem(LANGUAGE_KEY, language)
  return language
}

export default setLanguageInLocalStorage
